import update from "immutability-helper";
import { headers } from "../../helper/api";
import base from "../../config";
import axios from "axios";
import {
  ATRFormModel,
  EventOpsFormModel,
  TuringFormModel,
  StormSeerFormModel,
  ATRSaaSFormModel,
  DOAFormModel,
  TAFormModel,
  SPFormModel,
  REFormModel,
  DPFormModel,
  GWLFormModel,
  ADFormModel,
  WPFormModel,
  MPFormModel,
  PIFormModel,
  AALFormModel,
  OPSFormModel,
  PAMFormModel,
  UTKMFormModel,
  SplunkClusterFormModel,
  Stack360FormModel,
  StormWatchFormModel,
  AutomationJourneyFormModel,
  AutomationPlannerFormModel,
  IntelligentOpportunityMinerFormModel,
  GuidedTicketResolverFormModel,
  KlewerFormModel,
  QuasarFormModel,
  VirtualDataScientistFormModel,
} from "../../constants/formModels";
import { isNull } from "lodash";


//Action
const START_LOADING = "fui/START_LOADING";
const START_SUBMIT_FORM_LOADING = "fui/START_SUBMIT_FORM_LOADING"
const ASYNC_ERROR = "fui/ASYNC_ERROR";
const SET_CONFIGURATION_TYPE = "fui/SET_CONFIGURATION_TYPE";
const SET_ACTIVE_STEP_TYPE = "fui/SET_ACTIVE_STEP_TYPE"
const UPDATE_A_SELECTED_INSTANCE = "fui/UPDATE_A_SELECTED_INSTANCE";
const UPDATE_A_SELECTED_INSTANCE_BY_FIELD = "fui/UPDATE_A_SELECTED_INSTANCE_BY_FIELD";

const FETCH_INSTANCE_DATA_SUCCESS = "fui/FETCH_INSTANCE_DATA_SUCCESS";
const FETCH_APPROVERS_DATA_SUCCESS = "fui/FETCH_APPROVERS_DATA_SUCCESS";
const FETCH_ORDER_REQUESTS_DATA_SUCCESS = "fui/FETCH_ORDER_REQUESTS_DATA_SUCCESS";
const SUBMIT_FORM_SUCCESS = "fui/SUBMIT_FORM_SUCCESS";
const SUBMIT_FORM_FAILED = "fui/SUBMIT_FORM_FAILED";

const SUBMIT_FORM_VALIDATION_SUCCESS = "fui/SUBMIT_FORM_VALIDATION_SUCCESS";
const CHECK_ROOT_EXIST = "fui/CHECK_ROOT_EXIST";
const CHECK_ROOT_NOT_EXIST = "fui/CHECK_ROOT_NOT_EXIST";
const CLEAR_ROOT_EXIST = "fui/CLEAR_ROOT_EXIST";
const CLEAR_EXTERNAL_CLIENT_DATA = "fui/CLEAR_EXTERNAL_CLIENT_DATA";

const UPDATE_ATR_FORM = "fui/UPDATE_ATR_FORM";
const UPDATE_EVENTOPS_FORM = "fui/UPDATE_EVENTOPS_FORM";
const UPDATE_TURING_FORM = "fui/UPDATE_TURING_FORM";
const CLEAR_ATR_FORM = "fui/CLEAR_ATR_FORM";
const CLEAR_EVENTOPS_FORM = "fui/CLEAR_EVENTOPS_FORM";
const CLEAR_TURING_FORM = "fui/CLEAR_TURING_FORM";
const UPDATE_STORMSEER_FORM = "fui/UPDATE_STORMSEER_FORM";
const CLEAR_STORMSEER_FORM = "fui/CLEAR_STORMSEER_FORM";
const UPDATE_ATRSaaS_FORM = "fui/UPDATE_ATRSaaS_FORM";
const CLEAR_ATRSaaS_FORM = "fui/CLEAR_ATRSaaS_FORM";
const UPDATE_TA_FORM = "fui/UPDATE_TA_FORM";
const CLEAR_TA_FORM = "fui/CLEAR_TA_FORM";
const UPDATE_SP_FORM = "fui/UPDATE_SP_FORM";
const CLEAR_SP_FORM = "fui/CLEAR_SP_FORM";
const UPDATE_RE_FORM = "fui/UPDATE_RE_FORM";
const CLEAR_RE_FORM = "fui/CLEAR_RE_FORM";
const UPDATE_DOA_FORM = "fui/UPDATE_DOA_FORM";
const CLEAR_DOA_FORM = "fui/CLEAR_DOA_FORM";
const UPDATE_DP_FORM = "fui/UPDATE_DP_FORM";
const UPDATE_GWL_FORM = "fui/UPDATE_GWL_FORM";
const UPDATE_AD_FORM = "fui/UPDATE_AD_FORM";
const UPDATE_WP_FORM = "fui/UPDATE_WP_FORM";
const CLEAR_DP_FORM = "fui/CLEAR_DP_FORM";
const CLEAR_GWL_FORM = "fui/CLEAR_GWL_FORM";
const CLEAR_AD_FORM = "fui/CLEAR_AD_FORM";
const CLEAR_WP_FORM = "fui/CLEAR_WP_FORM";
const UPDATE_SPLUCLU_FORM = "fui/UPDATE_SPLUCLU_FORM";
const CLEAR_SPLUCLU_FORM = "fui/CLEAR_SPLUCLU_FORM";
const UPDATE_STACK360_FORM = "fui/UPDATE_STACK360_FORM";
const CLEAR_STACK360_FORM = "fui/CLEAR_STACK360_FORM";
const UPDATE_STORMWATCH_FORM = "fui/UPDATE_STORMWATCH_FORM";
const CLEAR_STORMWATCH_FORM = "fui/CLEAR_STORMWATCH_FORM";
const UPDATE_AUTOMATIONJOURNEY_FORM = "fui/UPDATE_AUTOMATIONJOURNEY_FORM";
const CLEAR_AUTOMATIONJOURNEY_FORM = "fui/CLEAR_AUTOMATIONJOURNEY_FORM";
const UPDATE_AUTOMATIONPLANNER_FORM = "fui/UPDATE_AUTOMATIONPLANNER_FORM";
const CLEAR_AUTOMATIONPLANNER_FORM = "fui/CLEAR_AUTOMATIONPLANNER_FORM";
const UPDATE_INTELLIGENTOPPORTUNITYMINER_FORM =
  "fui/UPDATE_INTELLIGENTOPPORTUNITYMINER_FORM";
const CLEAR_INTELLIGENTOPPORTUNITYMINER_FORM =
  "fui/CLEAR_INTELLIGENTOPPORTUNITYMINER_FORM";
const UPDATE_GUIDEDTICKETRESOLVER_FORM = "fui/UPDATE_GUIDEDTICKETRESOLVER_FORM";
const CLEAR_GUIDEDTICKETRESOLVER_FORM = "fui/CLEAR_GUIDEDTICKETRESOLVER_FORM";
const UPDATE_KLEWER_FORM = "fui/UPDATE_KLEWER_FORM";
const CLEAR_KLEWER_FORM = "fui/CLEAR_KLEWER_FORM";
const UPDATE_QUASAR_FORM = "fui/UPDATE_QUASAR_FORM";
const CLEAR_QUASAR_FORM = "fui/CLEAR_QUASAR_FORM";
const UPDATE_VIRTUALDATASCIENTIST_FORM = "fui/UPDATE_VIRTUALDATASCIENTIST_FORM";
const CLEAR_VIRTUALDATASCIENTIST_FORM = "fui/CLEAR_VIRTUALDATASCIENTIST_FORM";
const UPDATE_MP_FORM = "fui/UPDATE_MP_FORM";
const CLEAR_MP_FORM = "fui/CLEAR_MP_FORM";
const UPDATE_PI_FORM = "fui/UPDATE_PI_FORM";
const CLEAR_PI_FORM = "fui/CLEAR_PI_FORM";
const UPDATE_PAM_FORM = "fui/UPDATE_PAM_FORM";
const CLEAR_PAM_FORM = "fui/CLEAR_PAM_FORM";
const UPDATE_UTKM_FORM = "fui/UPDATE_UTKM_FORM";
const CLEAR_UTKM_FORM = "fui/CLEAR_UTKM_FORM";
const UPDATE_OPS_FORM = "fui/UPDATE_OPS_FORM";
const CLEAR_OPS_FORM = "fui/CLEAR_OPS_FORM";
const UPDATE_AAL_FORM = "fui/UPDATE_AAL_FORM";
const CLEAR_AAL_FORM = "fui/CLEAR_AAL_FORM";

const SET_IS_FDS_ENABLED = "fui/SET_IS_FDS_ENABLED";

const RESET_FUI_STATE = "fui/RESET_FUI_STATE";
const CLEAR_LOCAL_COMPONENT_DATA = "CLEAR_LOCAL_COMPONENT_DATA";
const START_EXTERNAL_CLIENT_LOADING = "fui/START_EXTERNAL_CLIENT_LOADING";
const FETCH_EXTERNAL_CLIENT_DATA = "fui/FETCH_EXTERNAL_CLIENT_DATA";
const FETCH_EXTERNAL_CLIENT_DATA_NULL = "fui/FETCH_EXTERNAL_CLIENT_DATA_NULL";
export const CHECK_IS_USER_APPROVAL_WHITELISTED = "home/CHECK_IS_USER_APPROVAL_WHITELISTED";

//URLs
const provisioningUrl = process.env.REACT_APP_PROVISION_URL;
const submitFormUrl = `${base.urls.apis.order}/update/new`;
const validateFormUrl = `${base.urls.apis.order}/validate`;
const fetchApproversUrl = `${base.urls.apis.details}/approvers`;
const fetchInstanceDataUrl = `${base.urls.apis.fui}`;
const fetchOrderRequestsDataUrl = `${base.urls.apis.order}/get-orders/all`;
const fetchFuiDetailsUrl = `${base.urls.apis.fulfillment}`;
const fetchRootUrl = `${base.urls.apis.fui}`;
const checkIsUserApprovalWhitelistedUrl = `${base.urls.apis.details}/isUserWhitelisted`;

//Reducer
const initState = {
  aSelectedInstance: {},
  instanceData: [],
  approversData: [],
  isLoading: true,
  isSubmitFormLoading: false,
  error: {},
  uidError: true,
  ATRForm: ATRFormModel,
  EventOpsForm: EventOpsFormModel,
  TuringForm: TuringFormModel,
  StormSeerForm: StormSeerFormModel,
  ATRSaaSForm: ATRSaaSFormModel,
  TAForm: TAFormModel,
  SPForm: SPFormModel,
  REForm: REFormModel,
  DOAForm: DOAFormModel,
  DPForm: DPFormModel,
  GWLForm: GWLFormModel,
  ADForm: ADFormModel,
  WPForm: WPFormModel,
  MPForm: MPFormModel,
  PIForm: PIFormModel,
  AALForm: AALFormModel,
  OPSForm: OPSFormModel,
  PAMForm: PAMFormModel,
  UTKMForm: UTKMFormModel,
  SPLUCLUForm: SplunkClusterFormModel,
  Stack360Form: Stack360FormModel,
  StormWatchForm: StormWatchFormModel,
  AutomationJourneyForm: AutomationJourneyFormModel,
  AutomationPlannerForm: AutomationPlannerFormModel,
  IntelligentOpportunityMinerForm: IntelligentOpportunityMinerFormModel,
  GuidedTicketResolverForm: GuidedTicketResolverFormModel,
  KlewerForm: KlewerFormModel,
  QuasarForm: QuasarFormModel,
  VirtualDataScientistForm: VirtualDataScientistFormModel,
  isFormSubmittedSuccessful: false,
  isFormSubmittedFailed: false,
  isFormValidationSuccessful: false,
  isRootExist: false,
  configurationType: "full",
  isFDSEnabled: true,
  listenerClearLocalData: false,
  apiError: false,
  isExternalClientLoading: false,
  externalClientError: false,
  externalClientData: [],
  activeStep: 0,
  isUserApprovalWhitelisted: false,
};

export default function reducer(state = initState, action) {

  const data = action.data;


  switch (action.type) {
    //Update data selected from instance list
    case UPDATE_A_SELECTED_INSTANCE:
      return update(state, { aSelectedInstance: { $set: action.data } });
    case UPDATE_A_SELECTED_INSTANCE_BY_FIELD:
      return update(state, {
        aSelectedInstance: { [data.field]: { $set: data.value } },
      });
    //Update products form
    case UPDATE_ATR_FORM:
      return update(state, { ATRForm: { [data.field]: { $set: data.value } } });
    case UPDATE_EVENTOPS_FORM:
      return update(state, { EventOpsForm: { [data.field]: { $set: data.value } } });
    case UPDATE_TURING_FORM:
      return update(state, { TuringForm: { [data.field]: { $set: data.value } } });
    case CLEAR_ATR_FORM:
      return update(state, { ATRForm: { $set: data } });
    case CLEAR_EVENTOPS_FORM:
      return update(state, { EventOpsForm: { $set: data } });
    case CLEAR_TURING_FORM:
      return update(state, { TuringForm: { $set: data } });
    case UPDATE_STORMSEER_FORM:
      return update(state, { StormSeerForm: { [data.field]: { $set: data.value } } });
    case CLEAR_STORMSEER_FORM:
      return update(state, { StormSeerForm: { $set: data } });
    case UPDATE_ATRSaaS_FORM:
      return update(state, {
        ATRSaaSForm: { [data.field]: { $set: data.value } },
      });
    case CLEAR_ATRSaaS_FORM:
      return update(state, { ATRSaaSForm: { $set: data } });
    case UPDATE_TA_FORM:
      return update(state, { TAForm: { [data.field]: { $set: data.value } } });
    case CLEAR_TA_FORM:
      return update(state, { TAForm: { $set: data } });
    case UPDATE_SP_FORM:
      return update(state, { SPForm: { [data.field]: { $set: data.value } } });
    case CLEAR_SP_FORM:
      return update(state, { SPForm: { $set: data } });
    case UPDATE_RE_FORM:
      return update(state, { REForm: { [data.field]: { $set: data.value } } });
    case CLEAR_RE_FORM:
      return update(state, { REForm: { $set: data } });
    case UPDATE_DOA_FORM:
      return update(state, { DOAForm: { [data.field]: { $set: data.value } } });
    case CLEAR_DOA_FORM:
      return update(state, { DOAForm: { $set: data } });
    case UPDATE_DP_FORM:
      return update(state, { DPForm: { [data.field]: { $set: data.value } } });
    case UPDATE_GWL_FORM:
      return update(state, { GWLForm: { [data.field]: { $set: data.value } } });
    case UPDATE_AD_FORM:
      return update(state, { ADForm: { [data.field]: { $set: data.value } } });
    case UPDATE_WP_FORM:
      return update(state, { WPForm: { [data.field]: { $set: data.value } } });
    case CLEAR_DP_FORM:
      return update(state, { DPForm: { $set: data } });
    case CLEAR_GWL_FORM:
      return update(state, { GWLForm: { $set: data } });
    case CLEAR_AD_FORM:
      return update(state, { ADForm: { $set: data } });
    case CLEAR_WP_FORM:
      return update(state, { WPForm: { $set: data } });
    case UPDATE_MP_FORM:
      return update(state, { MPForm: { [data.field]: { $set: data.value } } });
    case CLEAR_MP_FORM:
      return update(state, { MPForm: { $set: data } });
    case UPDATE_PI_FORM:
      return update(state, { PIForm: { [data.field]: { $set: data.value } } });
    case CLEAR_PI_FORM:
      return update(state, { PIForm: { $set: data } });
    case UPDATE_PAM_FORM:
      return update(state, { PAMForm: { [data.field]: { $set: data.value } } });
    case CLEAR_PAM_FORM:
      return update(state, { PAMForm: { $set: data } });
    case UPDATE_UTKM_FORM:
      return update(state, { UTKMForm: { [data.field]: { $set: data.value } } });
    case CLEAR_UTKM_FORM:
      return update(state, { UTKMForm: { $set: data } });
    case UPDATE_SPLUCLU_FORM:
      return update(state, {
        SPLUCLUForm: { [data.field]: { $set: data.value } },
      });
    case CLEAR_SPLUCLU_FORM:
      return update(state, { SPLUCLUForm: { $set: data } });
    case UPDATE_STACK360_FORM:
      return update(state, {
        Stack360Form: { [data.field]: { $set: data.value } },
      });
    case CLEAR_STACK360_FORM:
      return update(state, { Stack360Form: { $set: data } });
    case UPDATE_STORMWATCH_FORM:
      return update(state, {
        StormWatchForm: { [data.field]: { $set: data.value } },
      });
    case CLEAR_STORMWATCH_FORM:
      return update(state, { StormWatchForm: { $set: data } });
    case UPDATE_AUTOMATIONJOURNEY_FORM:
      return update(state, {
        AutomationJourneyForm: { [data.field]: { $set: data.value } },
      });
    case CLEAR_AUTOMATIONJOURNEY_FORM:
      return update(state, { AutomationJourneyForm: { $set: data } });
    case UPDATE_AUTOMATIONPLANNER_FORM:
      return update(state, {
        AutomationPlannerForm: { [data.field]: { $set: data.value } },
      });
    case CLEAR_AUTOMATIONPLANNER_FORM:
      return update(state, { AutomationPlannerForm: { $set: data } });
    case UPDATE_INTELLIGENTOPPORTUNITYMINER_FORM:
      return update(state, {
        IntelligentOpportunityMinerForm: { [data.field]: { $set: data.value } },
      });
    case CLEAR_INTELLIGENTOPPORTUNITYMINER_FORM:
      return update(state, { IntelligentOpportunityMinerForm: { $set: data } });
    case UPDATE_GUIDEDTICKETRESOLVER_FORM:
      return update(state, {
        GuidedTicketResolverForm: { [data.field]: { $set: data.value } },
      });
    case CLEAR_GUIDEDTICKETRESOLVER_FORM:
      return update(state, { GuidedTicketResolverForm: { $set: data } });
    case UPDATE_KLEWER_FORM:
      return update(state, {
        KlewerForm: { [data.field]: { $set: data.value } },
      });
    case CLEAR_KLEWER_FORM:
      return update(state, { KlewerForm: { $set: data } });
    case UPDATE_QUASAR_FORM:
      return update(state, {
        QuasarForm: { [data.field]: { $set: data.value } },
      });
    case CLEAR_QUASAR_FORM:
      return update(state, { QuasarForm: { $set: data } });
    case UPDATE_VIRTUALDATASCIENTIST_FORM:
      return update(state, {
        VirtualDataScientistForm: { [data.field]: { $set: data.value } },
      });
    case CLEAR_VIRTUALDATASCIENTIST_FORM:
      return update(state, { VirtualDataScientistForm: { $set: data } });
    case UPDATE_OPS_FORM:
      return update(state, { OPSForm: { [data.field]: { $set: data.value } } });
    case CLEAR_OPS_FORM:
      return update(state, { OPSForm: { $set: data } })
    case UPDATE_AAL_FORM:
      return update(state, { AALForm: { [data.field]: { $set: data.value } } });
    case CLEAR_AAL_FORM:
      return update(state, { AALForm: { $set: data } })
    case SET_IS_FDS_ENABLED:
      return update(state, { isFDSEnabled: { $set: data } });
    //Choose a confiuration type
    case SET_CONFIGURATION_TYPE:
      return update(state, {
        configurationType: { $set: data },
      });
    case SET_ACTIVE_STEP_TYPE:
      return update(state, {
        activeStep: { $set: data },
      });
    //successfully fetch instance data from backend
    case FETCH_INSTANCE_DATA_SUCCESS:
      return update(state, {
        instanceData: { $set: data },
        isLoading: { $set: false },
      });

    case FETCH_ORDER_REQUESTS_DATA_SUCCESS:
      return update(state, {
        orderRequestsData: { $set: data },
        isLoading: { $set: false },
      });

    case FETCH_APPROVERS_DATA_SUCCESS:
      return update(state, {
        approversData: { $set: data },
        isLoading: { $set: false },
      });

    //Submit form
    case SUBMIT_FORM_SUCCESS:
      return update(state, {
        isSubmitFormLoading: { $set: false },
        isLoading: { $set: false },
        isFormSubmittedSuccessful: { $set: true },
      });


    case SUBMIT_FORM_FAILED:
      return update(state, {
        isSubmitFormLoading: { $set: false },
        isLoading: { $set: false },
        isFormSubmittedFailed: { $set: true },
      });

    case SUBMIT_FORM_VALIDATION_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        isFormValidationSuccessful: { $set: true },
        validationData: { $set: data },
      });

    case CHECK_ROOT_EXIST:
      return update(state, {
        isLoading: { $set: false },
        isRootExist: { $set: true },
      });
    case CHECK_ROOT_NOT_EXIST:
      return update(state, {
        isLoading: { $set: false },
        isRootExist: { $set: false },
      });
    case CLEAR_ROOT_EXIST:
      return update(state, {
        isRootExist: { $set: false },
      });
    case CLEAR_EXTERNAL_CLIENT_DATA:
      return update(state, {
        externalClientData: { $set: [] },
      });
    case CLEAR_LOCAL_COMPONENT_DATA:
      return {
        ...state,
        listenerClearLocalData: !state.listenerClearLocalData,
      };

    case START_EXTERNAL_CLIENT_LOADING:
      return update(state, { isExternalClientLoading: { $set: true } });

    case FETCH_EXTERNAL_CLIENT_DATA:
      return update(state, {
        externalClientData: { $set: data },
        isExternalClientLoading: { $set: false },
        externalClientError: { $set: false },
      });

    case CHECK_IS_USER_APPROVAL_WHITELISTED:
      if (action.type === "home/CHECK_IS_USER_APPROVAL_WHITELISTED") {
        return update(state, {
          isLoading: { $set: false },
          isUserApprovalWhitelisted: { $set: action.isUserApprovalWhitelisted },
        });
      }
      return { ...state };


    case FETCH_EXTERNAL_CLIENT_DATA_NULL:
      return update(state, {
        externalClientData: { $set: data },
        isExternalClientLoading: { $set: false },
        externalClientError: { $set: true },
      });

    //Reset fui redux state
    case RESET_FUI_STATE:
      return initState;
    //Start a action
    case START_LOADING:
      return update(state, { isLoading: { $set: true } });
    case START_SUBMIT_FORM_LOADING:
      return update(state, { isSubmitFormLoading: { $set: true } });
    //Deal with error
    case ASYNC_ERROR:
      return update(state, {
        error: { $set: data },
        isLoading: { $set: false },
        apiError: { $set: true },
      });
    default:
      return state;
  }
}

//Action Creator
export const updateASelectedInstance = (data) => ({
  data: data,
  type: UPDATE_A_SELECTED_INSTANCE,
});

export const updateASelectedInstanceByField = (data) => ({
  data: data,
  type: UPDATE_A_SELECTED_INSTANCE_BY_FIELD,
});

export const resetFuiState = () => ({
  type: RESET_FUI_STATE,
});

export const updateATRForm = (field, value) => ({
  type: UPDATE_ATR_FORM,
  data: { field, value },
});

export const updateEOForm = (field, value) => ({
  type: UPDATE_EVENTOPS_FORM,
  data: { field, value },
});

export const updateTuringForm = (field, value) => ({
  type: UPDATE_TURING_FORM,
  data: { field, value },
});

export const clearATRForm = (clearForm) => ({
  type: CLEAR_ATR_FORM,
  data: clearForm,
});

export const clearEOForm = (clearForm) => ({
  type: CLEAR_EVENTOPS_FORM,
  data: clearForm,
});

export const clearTuringForm = (clearForm) => ({
  type: CLEAR_TURING_FORM,
  data: clearForm,
});

export const updateStormSeerForm = (field, value) => ({
  type: UPDATE_STORMSEER_FORM,
  data: { field, value },
});

export const clearStormSeerForm = (clearForm) => ({
  type: CLEAR_STORMSEER_FORM,
  data: clearForm,
});

export const updateATRSaaSForm = (field, value) => ({
  type: UPDATE_ATRSaaS_FORM,
  data: { field, value },
});

export const clearATRSaaSForm = (clearForm) => ({
  type: CLEAR_ATRSaaS_FORM,
  data: clearForm,
});

export const updateTAForm = (field, value) => ({
  type: UPDATE_TA_FORM,
  data: { field, value },
});

export const clearTAForm = (clearForm) => ({
  type: CLEAR_TA_FORM,
  data: clearForm,
});

export const updateSPForm = (field, value) => ({
  type: UPDATE_SP_FORM,
  data: { field, value },
});

export const clearSPForm = (clearForm) => ({
  type: CLEAR_SP_FORM,
  data: clearForm,
});

export const updateREForm = (field, value) => ({
  type: UPDATE_RE_FORM,
  data: { field, value },
});

export const clearREForm = (clearForm) => ({
  type: CLEAR_RE_FORM,
  data: clearForm,
});

export const updateDOAForm = (field, value) => ({
  type: UPDATE_DOA_FORM,
  data: { field, value },
});

export const clearDOAForm = (clearForm) => ({
  type: CLEAR_DOA_FORM,
  data: clearForm,
});

export const updateDPForm = (field, value) => ({
  type: UPDATE_DP_FORM,
  data: { field, value },
});

export const updateUTKMForm = (field, value) => ({
  type: UPDATE_UTKM_FORM,
  data: { field, value },
});

export const updateGWLForm = (field, value) => ({
  type: UPDATE_GWL_FORM,
  data: { field, value },
})

export const updateADForm = (field, value) => ({
  type: UPDATE_AD_FORM,
  data: { field, value },
})

export const updateWPForm = (field, value) => ({
  type: UPDATE_WP_FORM,
  data: { field, value },
});

export const clearDPForm = (clearForm) => ({
  type: CLEAR_DP_FORM,
  data: clearForm,
});

export const clearUTKMForm = (clearForm) => ({
  type: CLEAR_UTKM_FORM,
  data: clearForm,
});

export const clearGWLForm = (clearForm) => ({
  type: CLEAR_GWL_FORM,
  data: clearForm,
});

export const clearADForm = (clearForm) => ({
  type: CLEAR_AD_FORM,
  data: clearForm,
});

export const clearWPForm = (clearForm) => ({
  type: CLEAR_WP_FORM,
  data: clearForm,
});

export const updateSPLUCLUForm = (field, value) => ({
  type: UPDATE_SPLUCLU_FORM,
  data: { field, value },
});

export const clearSPLUCLUForm = (clearForm) => ({
  type: CLEAR_SPLUCLU_FORM,
  data: clearForm,
});

export const updateStack360Form = (field, value) => ({
  type: UPDATE_STACK360_FORM,
  data: { field, value },
});

export const clearStack360Form = (clearForm) => ({
  type: CLEAR_STACK360_FORM,
  data: clearForm,
});

export const updateStormWatchForm = (field, value) => ({
  type: UPDATE_STORMWATCH_FORM,
  data: { field, value },
});

export const clearStormWatchForm = (clearForm) => ({
  type: CLEAR_STORMWATCH_FORM,
  data: clearForm,
});

export const updateAutomationJourneyForm = (field, value) => ({
  type: UPDATE_AUTOMATIONJOURNEY_FORM,
  data: { field, value },
});

export const clearAutomationJourneyForm = (clearForm) => ({
  type: CLEAR_AUTOMATIONJOURNEY_FORM,
  data: clearForm,
});

export const updateAutomationPlannerForm = (field, value) => ({
  type: UPDATE_AUTOMATIONPLANNER_FORM,
  data: { field, value },
});

export const clearAutomationPlannerForm = (clearForm) => ({
  type: CLEAR_AUTOMATIONPLANNER_FORM,
  data: clearForm,
});

export const updateIntelligentOpportunityMinerForm = (field, value) => ({
  type: UPDATE_INTELLIGENTOPPORTUNITYMINER_FORM,
  data: { field, value },
});

export const clearIntelligentOpportunityMinerForm = (clearForm) => ({
  type: CLEAR_INTELLIGENTOPPORTUNITYMINER_FORM,
  data: clearForm,
});

export const updateGuidedTicketResolverForm = (field, value) => ({
  type: UPDATE_GUIDEDTICKETRESOLVER_FORM,
  data: { field, value },
});

export const clearGuidedTicketResolverForm = (clearForm) => ({
  type: CLEAR_GUIDEDTICKETRESOLVER_FORM,
  data: clearForm,
});

export const updateKlewerForm = (field, value) => ({
  type: UPDATE_KLEWER_FORM,
  data: { field, value },
});

export const clearKlewerForm = (clearForm) => ({
  type: CLEAR_KLEWER_FORM,
  data: clearForm,
});

export const updateQuasarForm = (field, value) => ({
  type: UPDATE_QUASAR_FORM,
  data: { field, value },
});

export const clearQuasarForm = (clearForm) => ({
  type: CLEAR_QUASAR_FORM,
  data: clearForm,
});

export const updateVirtualDataScientistForm = (field, value) => ({
  type: UPDATE_VIRTUALDATASCIENTIST_FORM,
  data: { field, value },
});

export const clearVirtualDataScientistForm = (clearForm) => ({
  type: CLEAR_VIRTUALDATASCIENTIST_FORM,
  data: clearForm,
});

export const updateMPForm = (field, value) => ({
  type: UPDATE_MP_FORM,
  data: { field, value },
});

export const clearMPForm = (clearForm) => ({
  type: CLEAR_MP_FORM,
  data: clearForm,
});



export const updatePIForm = (field, value) => ({
  type: UPDATE_PI_FORM,
  data: { field, value },
});

export const clearPIForm = (clearForm) => ({
  type: CLEAR_PI_FORM,
  data: clearForm,
});

export const updatePAMForm = (field, value) => ({
  type: UPDATE_PAM_FORM,
  data: { field, value },
});

export const clearPAMForm = (clearForm) => ({
  type: CLEAR_PAM_FORM,
  data: clearForm,
});

export const updateOPSForm = (field, value) => ({
  type: UPDATE_OPS_FORM,
  data: { field, value },
});

export const clearOPSForm = (clearForm) => ({
  type: CLEAR_OPS_FORM,
  data: clearForm,
});

export const clearAALForm = (clearForm) => ({
  type: CLEAR_AAL_FORM,
  data: clearForm,
});

export const updateAALForm = (field, value) => ({
  type: UPDATE_AAL_FORM,
  data: { field, value },
});

export const setConfigurationType = (event) => ({
  type: SET_CONFIGURATION_TYPE,
  data: event.target.value,
});

export const setActiveStep = (data) => ({
  type: SET_ACTIVE_STEP_TYPE,
  data: data,
});

export const setIsFDSEnabled = (data) => ({
  type: SET_IS_FDS_ENABLED,
  data: data,
});

export const clearLocalComponentData = () => ({
  type: CLEAR_LOCAL_COMPONENT_DATA,
});

export const startLoading = () => ({
  type: START_LOADING,
});

export const startSubmitFormLoading = () => ({
  type: START_SUBMIT_FORM_LOADING,
});

export const startExternalClientLoading = () => ({
  type: START_EXTERNAL_CLIENT_LOADING,
});


export const fetchInstanceData = (email, idToken) => (dispatch, getState) => {
  // dispatch({ type: START_LOADING });
  const idToken = localStorage.getItem("token");
  const createdBy = email ? email.split("@")[0] : "";
  const activeAccount = getState().auth.activeAccount;

  if (email) {
    axios
      .get(fetchInstanceDataUrl, {
        headers: {
          ...headers,
          Authorization: idToken,
        },
        params: {
          customer_enterprise_id: createdBy,
          page_number: 1,
          page_size: 999,
          inititive: activeAccount,
        },
      })
      .then((res) => {
        const { data } = res;
        return dispatch({
          type: FETCH_INSTANCE_DATA_SUCCESS,
          data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ASYNC_ERROR,
          data: err,
        });
      });
  }
};

export const fetchOrderRequestsData = (email) => (dispatch, getState) => {
  // dispatch({ type: START_LOADING });
  const idToken = localStorage.getItem("token");
  const createdBy = email ? email.split("@")[0] : "";
  const activeAccount = getState().auth.activeAccount;

  if (email) {
    axios
      .get(fetchOrderRequestsDataUrl, {
        headers: {
          ...headers,
          Authorization: idToken,
        },
        params: {
          customer_enterprise_id: createdBy,
          inititive: activeAccount,
        },
      })
      .then((res) => {
        const { data } = res;
        return dispatch({
          type: FETCH_ORDER_REQUESTS_DATA_SUCCESS,
          data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ASYNC_ERROR,
          data: err,
        });
      });
  }
};

export const validateForm = (data) => (dispatch, getState) => {
  //Need to add active account
  const idToken = localStorage.getItem("token");
  dispatch({ type: START_LOADING });
  const { OrderUId } = getState().fui.aSelectedInstance;
  const submitData = OrderUId
    ? {
      ...data,
      instanceId: OrderUId,
    }
    : { ...data };

  const productId = submitData.OrderItems[0].ProductUId
  const configArray = submitData.OrderItems[0].Config
  const platformTypeValue = configArray.find(item => item.Key === "AaamPlatformType");
  const platformType = platformTypeValue['Value'];
  const projectId = submitData.OrderItems[0].ProjectId
  const vendorName = submitData.OrderItems[0].VendorName
  const sapContractIDValue = configArray.find(item => item.Key === "SAPContractID");
  const sapContractID = sapContractIDValue['Value'];
  const endToEndUIdValue = configArray.find(item => item.Key === "EndToEndUId");
  const endToEndUId = endToEndUIdValue['Value'];
  const customerEnterpriseIDValue = configArray.find(item => item.Key === "CustomerEnterpriseID");
  const customerEnterpriseID = customerEnterpriseIDValue['Value'];
  const configurationTypeValue = configArray.find(item => item.Key === "ConfigurationType");
  const configurationType = configurationTypeValue['Value'];

  let validateUrl = validateFormUrl;

  // console.log(JSON.stringify(submitData))
  axios
    .get(validateUrl, {
      headers: {
        ...headers,
        Authorization: idToken,
      },
      params: {
        AaamPlatformType: platformType,
        ProductUId: productId,
        ProjectId: projectId,
        EndToEndUId: endToEndUId,
        SAPContractID: sapContractID,
        CustomerEnterpriseID: customerEnterpriseID,
        VendorName: vendorName,
        ConfigurationType: configurationType
      },
    })
    .then((res) => {
      const { data } = res;
      dispatch({ type: SUBMIT_FORM_VALIDATION_SUCCESS, data });
    })
    .catch((err) => {
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });

  // along with validate order step, calling the get approvers list api as well
  let approversUrl = fetchApproversUrl;

  axios
    .get(approversUrl, {
      headers: {
        ...headers,
        Authorization: idToken,
      },
      params: {
        AaamPlatformType: platformType,
        ProductUId: productId
      },
    })
    .then((res) => {
      const { data } = res;
      dispatch({ type: FETCH_APPROVERS_DATA_SUCCESS, data });
    })
    .catch((err) => {
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

// WITHOUT QUEUING 
export const submitForm = (data) => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  dispatch({ type: START_LOADING });
  dispatch({ type: START_SUBMIT_FORM_LOADING });
  const { OrderUId } = getState().fui.aSelectedInstance;
  const submitData = OrderUId
    ? {
      ...data,
      instanceId: OrderUId,
    }
    : { ...data };

  let url = submitFormUrl;
  let url1 = provisioningUrl;

  //check if Approver is equal to Requester or not
  const configArray = submitData.OrderItems[0].Config
  const approverValue = configArray.find(item => item.Key === "Approver");
  const approver = approverValue['Value'].split("@")[0];
  const requesterValue = configArray.find(item => item.Key === "CustomerEnterpriseID");
  const requester = requesterValue['Value'];

  if (approver === requester) {
    const approvalStatus = configArray.find(item => item.Key === "ApprovalStatus");
    approvalStatus['Value'] = "Approved";
    const approvedBy = configArray.find(item => item.Key === "ApprovedBy");
    approvedBy['Value'] = approver;
    //hit update DocumentDB url & trigger mail endpoint
    axios
      .post(url, submitData, { headers: { Authorization: idToken } })
      .then((res) => {
        // hit final provisioning endpoint
        axios
          .post(url1, data, { headers: { Authorization: idToken } })
          .then((res) => {
            dispatch({ type: SUBMIT_FORM_SUCCESS });
          })
          .catch((err) => {
            dispatch({ type: SUBMIT_FORM_FAILED });
            dispatch({
              type: ASYNC_ERROR,
              data: err,
            });
          });
      })
      .catch((err) => {
        dispatch({ type: SUBMIT_FORM_FAILED });
        dispatch({
          type: ASYNC_ERROR,
          data: err,
        });
      });
  } else {
    //hit update DocumentDB url & trigger mail endpoint
    axios
      .post(url, submitData, { headers: { Authorization: idToken } })
      .then((res) => {
        dispatch({ type: SUBMIT_FORM_SUCCESS });
      })
      .catch((err) => {
        dispatch({ type: SUBMIT_FORM_FAILED });
        dispatch({
          type: ASYNC_ERROR,
          data: err,
        });
      });
  }
};

export const submitFormNonApproval = (data) => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  dispatch({ type: START_LOADING });
  dispatch({ type: START_SUBMIT_FORM_LOADING });
  const { OrderUId, OrderItemUId } = getState().fui.aSelectedInstance;
  const submitData = OrderUId
    ? {
      ...data,
      instanceId: OrderUId,
      InstanceTypeUId: OrderItemUId,
    }
    : { ...data, InstanceTypeUId: "00000000-0000-0000-0000-000000000000" };

  let url = provisioningUrl;
  // hit final provisioning endpoint
  // console.log(JSON.stringify(submitData))
  axios.post(url, submitData, { headers: { Authorization: idToken } })
    .then((res) => {
      dispatch({ type: SUBMIT_FORM_SUCCESS });
    })
    .catch((err) => {
      dispatch({ type: SUBMIT_FORM_FAILED });
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

// WITH QUEUING 
// export const submitForm = (data) => (dispatch, getState) => {
//   const idToken = localStorage.getItem("token");
//   dispatch({ type: START_LOADING });
//   const { OrderUId } = getState().fui.aSelectedInstance;
//   const submitData = OrderUId
//     ? {
//       ...data,
//       instanceId: OrderUId,
//     }
//     : { ...data };

//   let url = submitFormUrl;

//   //check if Approver is equal to Requester or not
//   const configArray = submitData.OrderItems[0].Config
//   const approverValue = configArray.find(item => item.Key === "Approver");
//   const approver = approverValue['Value'].split("@")[0];
//   const requesterValue = configArray.find(item => item.Key === "CustomerEnterpriseID");
//   const requester = requesterValue['Value'];

//   if (approver === requester){
//     const approvalStatus = configArray.find(item => item.Key === "ApprovalStatus");
//     approvalStatus['Value'] = "Approved";
//     //hit update DocumentDB url & trigger mail endpoint
//     axios
//       .post(url, submitData, { headers: { Authorization: idToken } })
//       .then((res) => {
//         dispatch({ type: SUBMIT_FORM_SUCCESS });
//       })
//       .catch((err) => {
//         dispatch({ type: SUBMIT_FORM_FAILED });
//         dispatch({
//           type: ASYNC_ERROR,
//           data: err,
//         });
//       }); 
//   } else {
//     //hit update DocumentDB url & trigger mail endpoint
//     axios
//       .post(url, submitData, { headers: { Authorization: idToken } })
//       .then((res) => {
//         dispatch({ type: SUBMIT_FORM_SUCCESS });
//       })
//       .catch((err) => {
//         dispatch({ type: SUBMIT_FORM_FAILED });
//         dispatch({
//           type: ASYNC_ERROR,
//           data: err,
//         });
//       });
//     }
// };

export const submitFormPAMapp = (data) => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  dispatch({ type: START_LOADING });
  dispatch({ type: START_SUBMIT_FORM_LOADING });
  const { OrderUId, OrderItemUId } = getState().fui.aSelectedInstance;
  let submitData = OrderItemUId
    ? {
      ...data,
      instanceId: OrderUId,
      InstanceTypeUId: OrderItemUId,
    }
    : { ...data, InstanceTypeUId: "00000000-0000-0000-0000-000000000000" };

  let url = provisioningUrl;

  axios
    .post(url, submitData, { headers: { Authorization: idToken } })
    .then((res) => {
      dispatch({ type: SUBMIT_FORM_SUCCESS });
    })
    .catch((err) => {
      dispatch({ type: SUBMIT_FORM_FAILED });
      dispatch({
        type: ASYNC_ERROR,
        data: err,
      });
    });
};

export const checkRootExisted = (value) => (dispatch, getState) => {
  const activeAccount = getState().auth.activeAccount;

  const idToken = localStorage.getItem("token");
  dispatch({ type: START_LOADING });
  const re = /^[a-z0-9]*$/;
  if (re.test(value)) {
    axios
      .get(fetchRootUrl, {
        headers: { ...headers, Authorization: idToken },
        params: {
          domain_context_root: value,
          inititive: activeAccount,
        },
      })
      .then((res) => {
        const root = res.data === "true" ? true : false;
        root
          ? dispatch({ type: CHECK_ROOT_EXIST })
          : dispatch({ type: CHECK_ROOT_NOT_EXIST });
      })
      .catch((err) => {
        dispatch({
          type: ASYNC_ERROR,
          data: err,
        });
      });

  } else {
    dispatch({ type: CHECK_ROOT_EXIST });
  }


};

export const clearIsRootExisted = () => ({
  type: CLEAR_ROOT_EXIST,
});

export const clearExternalClientData = () => ({
  type: CLEAR_EXTERNAL_CLIENT_DATA,
});


export const fetchExternalClientDetails = (contractCode) => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const urls = `${fetchFuiDetailsUrl}/client-details`;
  dispatch({ type: START_EXTERNAL_CLIENT_LOADING });
  axios
    .get(urls, {
      headers: {
        ...headers,
        Authorization: idToken,
      },
      params: {
        contract_code: contractCode,
      },
    })
    .then((res) => {
      const { data } = res;
      if (isNull(data[0])) {
        return dispatch({
          type: FETCH_EXTERNAL_CLIENT_DATA_NULL,
          data,
        });
      } else if (data[0] === "N/A") {
        return dispatch({
          type: FETCH_EXTERNAL_CLIENT_DATA_NULL,
          data,
        });
      } else {
        return dispatch({
          type: FETCH_EXTERNAL_CLIENT_DATA,
          data,
        });
      }

    })
    .catch((err) => {

    });
};

export const checkIsUserApprovalWhitelisted = () => (dispatch, getState) => {
  const idToken = localStorage.getItem("token");
  const email = getState().auth.userDetails.email;
  const eId = email === undefined ? undefined : email.split("@")[0];
  const activeAccount = getState().auth.activeAccount;

  const url2 = `${checkIsUserApprovalWhitelistedUrl}?userepid=${eId}`;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };
  const apiCall = async () => {
    try {
      const res = await axios
        .get(url2, options);
      // console.log("res:::",res);
      dispatch({ type: CHECK_IS_USER_APPROVAL_WHITELISTED, isUserApprovalWhitelisted: res.data });
      localStorage.setItem("isUserApprovalWhitelisted", res.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  return apiCall();
};

