export const dataCategorysClient = [
    {
        "value": "Client-Production",
        "label": "Client Production"
    },
    {
        "value": "Client-NonProduction",
        "label": "Client Non-Production"
    }
]

export const dataCategorysInternal =[
    {
        "value": "Internal-Production",
        "label": "Internal Production"
    },
    {
        "value": "AppTeam-Development",
        "label": "Development"
    },
    {
        "value": "AppTeam-Testing",
        "label": "Testing"
    }
]