import moment from "moment";
import { appNameProjectId } from "../components/fui/Constants/ProductIds";

export const generateSubmitData = (
  uiData,
  orderUId,
  orderItemUId,
  createdBy,
  userOrder,
  formName
) => {
  const currentTime = moment().format();

  const config = Object.entries(uiData).map((pair) => ({
    Key: pair[0],
    Value: pair[1],
  }));
  //trim string for RequestNotes
  const requestNotesIndex = config.findIndex((o) => o.Key === "RequestNotes");
  config[requestNotesIndex].Value.trim().replace(/\s+/g, " ");

  const generalAppForm = {
    OrderUId: `${orderUId}`,
    UserOrder: `${
      uiData.FDSEnabled === "yes"
        ? uiData.EndToEndUId + userOrder
        : uiData.DMSId + userOrder
      }`,
    ClientName: uiData.ClientName,
    EndToEndUId: `${
      uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
      }`, //`${uiData.FDSEnabled === "yes" ? uiData.EndToEndUId : ""}`,
    DMSId: uiData.DMSId === "" ? uiData.EndToEndUId : uiData.DMSId,
    DeployRegion: uiData.DeployRegion,
    CloudPlatform: "AWS",
    MyClient: "myWizard AiOps",
    OS: "CentOS",
    DomainContextRoot: uiData.DomainContextRoot,
    OrderItems: [
      {
        ProjectId: `${
          uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
          }`,
        OrderItemUId: `${orderItemUId}`,
        CreatedBy: createdBy,
        CreatedDate: `${currentTime}`,
        CreateDate: `${currentTime}`,
        ModifiedBy: "",
        ModifiedDate: "",
        ResolvedBy: "",
        ResolvedDate: "",
        Status: "Placed",
        ProductUId: "",
        VendorName: "CAM",
        Notes: "",
        E2EProjectId: `${uiData.E2EProjectId}`,
        Users: [{ Id: uiData.Users }],
        Config: config,
        DeliveryOptions: null,
        ArtifactAttributes: null,
        AccessLinkURL: "",
      },
    ],
    __v: 0,
  };

  switch (formName) {
    case "AutomaticTicketResolver":
      generalAppForm.OrderItems[0]["ProductUId"] =
        appNameProjectId.automaticTicketResolverId;
      break;
    case "Turing":
      generalAppForm.OrderItems[0]["ProductUId"] =
        appNameProjectId.turingId;
      break;
    case "StormSeer":
      generalAppForm.OrderItems[0]["ProductUId"] =
        appNameProjectId.stormseerId;
      break;
    case "Sharepoint":
      generalAppForm.OrderItems[0]["ProductUId"] =
        appNameProjectId.sharepointAddOnId;
      break;
    case "DevOpsAnalytics":
      generalAppForm.OrderItems[0]["ProductUId"] =
        appNameProjectId.devOpsAnalyticsId;
      break;
    case "TicketAnalysis":
      generalAppForm.OrderItems[0]["ProductUId"] =
        appNameProjectId.ticketAnalysisId;
      break;
    case "ReportExtractor":
      generalAppForm.OrderItems[0]["ProductUId"] =
        appNameProjectId.reportExtractorAddOnId;
      break;
    case "StormWatch":
      generalAppForm.OrderItems[0]["ProductUId"] =
        appNameProjectId.stormwatchId;
      break;
    default:
      break;
  }
  return generalAppForm;
};

export const generatePAMAppSubmitData = (
  uiData,
  orderUId,
  orderItemUId,
  createdBy,
  userOrder,
  formName,
  dataFromInstance
) => {
  const currentTime = moment().format();

  const config = Object.entries(uiData).map((pair) => ({
    Key: pair[0],
    Value: pair[1],
  }));
  //trim string for RequestNotes
  const requestNotesIndex = config.findIndex((o) => o.Key === "RequestNotes");
  config[requestNotesIndex].Value.trim().replace(/\s+/g, " ");

  const generalAppForm = {
    OrderUId: `${orderUId}`,
    UserOrder: `${
      uiData.FDSEnabled === "yes"
        ? uiData.EndToEndUId + userOrder
        : uiData.DMSId + userOrder
      }`,
    ClientName: uiData.ClientName,
    EndToEndUId: `${
      uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
      }`, //`${uiData.FDSEnabled === "yes" ? uiData.EndToEndUId : ""}`,
    DMSId: uiData.DMSId === "" ? uiData.EndToEndUId : uiData.DMSId,
    DeployRegion: uiData.DeployRegion,
    DomainContextRoot: uiData.DomainContextRoot,
    OrderItems: [
      {
        ProjectId: `${
          uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
          }`,
        OrderItemUId: `${orderItemUId}`,
        CreatedBy: createdBy,
        CreatedDate: `${currentTime}`,
        CreateDate: `${currentTime}`,
        ModifiedBy: "",
        ModifiedDate: "",
        ResolvedBy: "",
        ResolvedDate: "",
        Status: "Placed",
        ProductUId: "",
        VendorName: "CAM",
        Notes: "",
        E2EProjectId: `${uiData.E2EProjectId}`,
        Users: [{ Id: uiData.Users }],
        Config: [
          ...config,
          {
            Key: "PAMPlatformType",
            Value: "PAM Premium",
          },
        ],
        DeliveryOptions: null,
        ArtifactAttributes: null,
        AccessLinkURL: "",
        Initiative: "PAM",
      },
    ],
    __v: 0,
  };

  switch (formName) {
    case "AutomationJourney":
      generalAppForm.OrderItems[0]["ProductUId"] =
        appNameProjectId.automationJourneyId;
      // generalAppForm.OrderItems[0]["PAMApps"] = ["AJ", "AP", "IOM"];
      generalAppForm.OrderItems[0]["PAMApps"] = ["AJ", "AP"];
      break;
    case "AutomationPlanner":
      generalAppForm.OrderItems[0]["ProductUId"] =
        appNameProjectId.automationPlannerId;
      // generalAppForm.OrderItems[0]["PAMApps"] = ["AP", "IOM"];
      generalAppForm.OrderItems[0]["PAMApps"] = ["AP"];
      break;
    case "IntelligentOpportunityPlanner":
      generalAppForm.OrderItems[0]["ProductUId"] =
        appNameProjectId.intelligentOpportunityMinerId;
      // generalAppForm.OrderItems[0]["PAMApps"] = ["IOM"];
      generalAppForm.OrderItems[0]["PAMApps"] = [""];
      break;
    case "GuidedTicketResolver":
      generalAppForm.OrderItems[0]["ProductUId"] =
        appNameProjectId.guidedTicketResolverId;
      generalAppForm.OrderItems[0]["PAMApps"] = ["GTR"];
      break;
    case "Klewer":
      generalAppForm.OrderItems[0]["ProductUId"] = appNameProjectId.klewerId;
      generalAppForm.OrderItems[0]["PAMApps"] = ["Klewer"];
      break;
    case "Quasar":
      generalAppForm.OrderItems[0]["ProductUId"] = appNameProjectId.quasarId;
      generalAppForm.OrderItems[0]["PAMApps"] = ["Quasar"];
      break;
    case "VirtualDataScientist":
      generalAppForm.OrderItems[0]["ProductUId"] =
        appNameProjectId.virtualDataScientistId;
      generalAppForm.OrderItems[0]["PAMApps"] = ["VDS"];
      break;
    default:
      break;
  }
  return generalAppForm;
};

export const generateUTKMSubmitData = (
  uiData,
  orderUId,
  orderItemUId,
  createdBy,
  userOrderATR,
  isFDSEnabled
) => {
  const currentTime = moment().format();
  const config = Object.entries(uiData).map((pair) => ({
    Key: pair[0],
    Value: pair[1],
  }));
  //trim string for RequestNotes
  const requestNotesIndex = config.findIndex((o) => o.Key === "RequestNotes");
  config[requestNotesIndex].Value.trim().replace(/\s+/g, " ");
  const aaamPlatformTypeIndex = config.findIndex((o) => o.Key === "AaamPlatformType");
  config[aaamPlatformTypeIndex].Value = "myWizard AiOps UTKM Platform"
  

  return {
    OrderUId: `${orderUId}`,
    UserOrder: `${
      uiData.FDSEnabled === "yes"
        ? uiData.EndToEndUId + userOrderATR
        : uiData.DMSId + userOrderATR
      }`,
    ClientName: uiData.ClientName,
    FDSEnabled: isFDSEnabled,
    EndToEndUId: `${
      uiData.EndToEndUId === "" ? uiData.DomainContextRoot : uiData.EndToEndUId
      }`,
    DMSId: `${
      uiData.DMSId === "" ? uiData.EndToEndUId : uiData.DomainContextRoot
      }`,
    DeployRegion: uiData.DeployRegion,
    CloudPlatform: "AWS",
    MyClient: "myWizard AiOps",
    OS: "CentOS",
    DomainContextRoot: uiData.DomainContextRoot,
    InstanceType: `${
      uiData.InstanceType ? uiData.InstanceType : "Dedicated"
      }`,
    OrderItems: [
      {
        ProjectId: `${
          uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
          }`,
        OrderItemUId: `${orderItemUId}`,
        CreatedBy: createdBy,
        CreatedDate: `${currentTime}`,
        CreateDate: `${currentTime}`,
        ModifiedBy: "",
        ModifiedDate: "",
        ResolvedBy: "",
        ResolvedDate: "",
        Status: "Placed",
        ContainerStatus: `${
          uiData.InstanceType === "Container" ? "Placed" : ""
          }`,
        InstanceType: `${
          uiData.InstanceType ? uiData.InstanceType : "Dedicated"
          }`,
        ProductUId: `${
          uiData.InstanceType ? ( uiData.InstanceType === "Dedicated"  ? "2a16243e-0d90-410a-a79c-9a2d5f0e3e2s" : "380afbb0-1fb0-4f81-9cc6-3e8b5a8661f0" ) : "2a16243e-0d90-410a-a79c-9a2d5f0e3e2s"
          }`,
        // ProductUId: "40f628ec-a32c-43d4-a42c-ddfb222ccbb2",
        VendorName: "CAM",
        Notes: "",
        E2EProjectId: `${uiData.E2EProjectId}`,
        Users: [{ Id: uiData.Users }],
        Config: config,
        DeliveryOptions: null,
        ArtifactAttributes: null,
        AccessLinkURL: "",
        // CloudPlatform: "AWS",
        // MyClient: "myWizard AiOps"
      },
    ],
    __v: 0,
  };
};

export const generateATRSubmitData = (
  uiData,
  orderUId,
  orderItemUId,
  createdBy,
  userOrderATR,
  isFDSEnabled
) => {
  const currentTime = moment().format();
  const config = Object.entries(uiData).map((pair) => ({
    Key: pair[0],
    Value: pair[1],
  }));
  //trim string for RequestNotes
  const requestNotesIndex = config.findIndex((o) => o.Key === "RequestNotes");
  config[requestNotesIndex].Value.trim().replace(/\s+/g, " ");
  const aaamPlatformTypeIndex = config.findIndex((o) => o.Key === "AaamPlatformType");
  if (uiData.InstanceType === "Container"){
    config[aaamPlatformTypeIndex].Value = "myWizard AiOps ATR Caas"
  } else {
    config[aaamPlatformTypeIndex].Value = "myWizard AiOps Automatic Ticket Resolver Platform"
  }

  return {
    OrderUId: `${orderUId}`,
    UserOrder: `${
      uiData.FDSEnabled === "yes"
        ? uiData.EndToEndUId + userOrderATR
        : uiData.DMSId + userOrderATR
      }`,
    ClientName: uiData.ClientName,
    FDSEnabled: isFDSEnabled,
    EndToEndUId: `${
      uiData.EndToEndUId === "" ? uiData.DomainContextRoot : uiData.EndToEndUId
      }`,
    DMSId: `${
      uiData.DMSId === "" ? uiData.EndToEndUId : uiData.DomainContextRoot
      }`,
    DeployRegion: uiData.DeployRegion,
    CloudPlatform: "AWS",
    MyClient: "myWizard AiOps",
    OS: "CentOS",
    DomainContextRoot: uiData.DomainContextRoot,
    InstanceType: `${
      uiData.InstanceType ? uiData.InstanceType : "Dedicated"
      }`,
    OrderItems: [
      {
        ProjectId: `${
          uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
          }`,
        OrderItemUId: `${orderItemUId}`,
        CreatedBy: createdBy,
        CreatedDate: `${currentTime}`,
        CreateDate: `${currentTime}`,
        ModifiedBy: "",
        ModifiedDate: "",
        ResolvedBy: "",
        ResolvedDate: "",
        Status: "Placed",
        ContainerStatus: `${
          uiData.InstanceType === "Container" ? "Placed" : ""
          }`,
        InstanceType: `${
          uiData.InstanceType ? uiData.InstanceType : "Dedicated"
          }`,
        ProductUId: `${
          uiData.InstanceType ? ( uiData.InstanceType === "Dedicated"  ? "40f628ec-a32c-43d4-a42c-ddfb222ccbb2" : "380afbb0-1fb0-4f81-9cc6-3e8b5a8661f0" ) : "40f628ec-a32c-43d4-a42c-ddfb222ccbb2"
          }`,
        // ProductUId: "40f628ec-a32c-43d4-a42c-ddfb222ccbb2",
        VendorName: "CAM",
        Notes: "",
        E2EProjectId: `${uiData.E2EProjectId}`,
        Users: [{ Id: uiData.Users }],
        Config: config,
        DeliveryOptions: null,
        ArtifactAttributes: null,
        AccessLinkURL: "",
        // CloudPlatform: "AWS",
        // MyClient: "myWizard AiOps"
      },
    ],
    __v: 0,
  };
};

export const generateEventOpsSubmitData = (
  uiData,
  orderUId,
  orderItemUId,
  createdBy,
  userOrderATR,
  isFDSEnabled
) => {
  const currentTime = moment().format();
  const config = Object.entries(uiData).map((pair) => ({
    Key: pair[0],
    Value: pair[1],
  }));
  //trim string for RequestNotes
  const requestNotesIndex = config.findIndex((o) => o.Key === "RequestNotes");
  config[requestNotesIndex].Value.trim().replace(/\s+/g, " ");
  const aaamPlatformTypeIndex = config.findIndex((o) => o.Key === "AaamPlatformType");
  // if (uiData.InstanceType === "Container") {
  //   config[aaamPlatformTypeIndex].Value = "myWizard AiOps ATR Caas"
  // } else {
  //   config[aaamPlatformTypeIndex].Value = "myWizard AiOps EventOps Platform"
  // }
  config[aaamPlatformTypeIndex].Value = "myWizard AiOps EventOps Platform"

  return {
    OrderUId: `${orderUId}`,
    UserOrder: `${uiData.FDSEnabled === "yes"
        ? uiData.EndToEndUId + userOrderATR
        : uiData.DMSId + userOrderATR
      }`,
    ClientName: uiData.ClientName,
    FDSEnabled: isFDSEnabled,
    EndToEndUId: `${uiData.EndToEndUId === "" ? uiData.DomainContextRoot : uiData.EndToEndUId
      }`,
    DMSId: `${uiData.DMSId === "" ? uiData.EndToEndUId : uiData.DomainContextRoot
      }`,
    DeployRegion: uiData.DeployRegion,
    CloudPlatform: "AWS",
    MyClient: "myWizard AiOps",
    OS: "CentOS",
    DomainContextRoot: uiData.DomainContextRoot,
    InstanceType: `${uiData.InstanceType ? uiData.InstanceType : "Dedicated"
      }`,
    OrderItems: [
      {
        ProjectId: `${uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
          }`,
        OrderItemUId: `${orderItemUId}`,
        CreatedBy: createdBy,
        CreatedDate: `${currentTime}`,
        CreateDate: `${currentTime}`,
        ModifiedBy: "",
        ModifiedDate: "",
        ResolvedBy: "",
        ResolvedDate: "",
        Status: "Placed",
        // ContainerStatus: `${uiData.InstanceType === "Container" ? "Placed" : ""  }`,
        EventOpsStatus: "Placed",
        InstanceType: `${uiData.InstanceType ? uiData.InstanceType : "Dedicated"
          }`,
        // ProductUId: `${uiData.InstanceType ? (uiData.InstanceType === "Dedicated" ? "ef06bc16-02a8-4225-9f25-8fa0e2700aaf" : "380afbb0-1fb0-4f81-9cc6-3e8b5a8661f0") : "ef06bc16-02a8-4225-9f25-8fa0e2700aaf"
        //   }`,
        ProductUId: "ef06bc16-02a8-4225-9f25-8fa0e2700aaf",
        VendorName: "CAM",
        Notes: "",
        E2EProjectId: `${uiData.E2EProjectId}`,
        Users: [{ Id: uiData.Users }],
        Config: config,
        DeliveryOptions: null,
        ArtifactAttributes: null,
        AccessLinkURL: "",
        // CloudPlatform: "AWS",
        // MyClient: "myWizard AiOps"
      },
    ],
    __v: 0,
  };
};

export const generateTuringSubmitData = (
  uiData,
  orderUId,
  orderItemUId,
  createdBy,
  userOrderATR,
  isFDSEnabled
) => {
  const currentTime = moment().format();

  const config = Object.entries(uiData).map((pair) => ({
    Key: pair[0],
    Value: pair[1],
  }));
  //trim string for RequestNotes
  const requestNotesIndex = config.findIndex((o) => o.Key === "RequestNotes");
  config[requestNotesIndex].Value.trim().replace(/\s+/g, " ");
  const aaamPlatformTypeIndex = config.findIndex((o) => o.Key === "AaamPlatformType");
  if (uiData.InstanceType === "Container"){
    config[aaamPlatformTypeIndex].Value = "myWizard AiOps Quasar Caas"
  } else {
    config[aaamPlatformTypeIndex].Value = "myWizard AiOps Q++ Platform"
  }

  return {
    OrderUId: `${orderUId}`,
    UserOrder: `${
      uiData.FDSEnabled === "yes"
        ? uiData.EndToEndUId + userOrderATR
        : uiData.DMSId + userOrderATR
      }`,
    ClientName: uiData.ClientName,
    FDSEnabled: isFDSEnabled,
    EndToEndUId: `${
      uiData.EndToEndUId === "" ? uiData.DomainContextRoot : uiData.EndToEndUId
      }`,
    DMSId: `${
      uiData.DMSId === "" ? uiData.EndToEndUId : uiData.DomainContextRoot
      }`,
    DeployRegion: uiData.DeployRegion,
    CloudPlatform: "AWS",
    MyClient: "myWizard AiOps",
    OS: "CentOS",
    DomainContextRoot: uiData.DomainContextRoot,
    InstanceType: `${
      uiData.InstanceType ? uiData.InstanceType : "Dedicated"
      }`,
    OrderItems: [
      {
        ProjectId: `${
          uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
          }`,
        OrderItemUId: `${orderItemUId}`,
        CreatedBy: createdBy,
        CreatedDate: `${currentTime}`,
        CreateDate: `${currentTime}`,
        ModifiedBy: "",
        ModifiedDate: "",
        ResolvedBy: "",
        ResolvedDate: "",
        Status: "Placed",
        ContainerStatus: `${
          uiData.InstanceType === "Container" ? "Placed" : ""
          }`,
        ProductUId: `${
          uiData.InstanceType ? ( uiData.InstanceType === "Dedicated"  ? "6f532d8b-15b9-48cf-9d0b-b9d8f26a5f6d" : "e0dcc7cd-ec6d-48ed-be47-5dffed5a2554" ) : "6f532d8b-15b9-48cf-9d0b-b9d8f26a5f6d"
          }`,
        // ProductUId: "6f532d8b-15b9-48cf-9d0b-b9d8f26a5f6d",
        VendorName: "CAM",
        Notes: "",
        E2EProjectId: `${uiData.E2EProjectId}`,
        Users: [{ Id: uiData.Users }],
        Config: [
          ...config,
          {
            Key: "Turing",
            Value: true,
          },
        ],
        DeliveryOptions: null,
        ArtifactAttributes: null,
        AccessLinkURL: "",
        // CloudPlatform: "AWS",
        // MyClient: "myWizard AiOps"
      },
    ],
    __v: 0,
  };
};

export const generateStormSeerSubmitData = (
  uiData,
  orderUId,
  orderItemUId,
  createdBy,
  userOrderStormSeer,
  isFDSEnabled
) => {
  const currentTime = moment().format();

  const config = Object.entries(uiData).map((pair) => ({
    Key: pair[0],
    Value: pair[1],
  }));
  //trim string for RequestNotes
  const requestNotesIndex = config.findIndex((o) => o.Key === "RequestNotes");
  config[requestNotesIndex].Value.trim().replace(/\s+/g, " ");

  return {
    OrderUId: `${orderUId}`,
    UserOrder: `${
      uiData.FDSEnabled === "yes"
        ? uiData.EndToEndUId + userOrderStormSeer
        : uiData.DMSId + userOrderStormSeer
      }`,
    ClientName: uiData.ClientName,
    FDSEnabled: isFDSEnabled,
    EndToEndUId: `${
      uiData.EndToEndUId === "" ? uiData.DomainContextRoot : uiData.EndToEndUId
      }`,
    DMSId: `${
      uiData.DMSId === "" ? uiData.EndToEndUId : uiData.DomainContextRoot
      }`,
    DeployRegion: uiData.DeployRegion,
    CloudPlatform: "AWS",
    MyClient: "myWizard AiOps",
    OS: "CentOS",
    DomainContextRoot: uiData.DomainContextRoot,
    OrderItems: [
      {
        ProjectId: `${
          uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
          }`,
        OrderItemUId: `${orderItemUId}`,
        CreatedBy: createdBy,
        CreatedDate: `${currentTime}`,
        CreateDate: `${currentTime}`,
        ModifiedBy: "",
        ModifiedDate: "",
        ResolvedBy: "",
        ResolvedDate: "",
        Status: "Placed",
        ProductUId: "b0471f50-eca3-4d99-8c3a-e0e42ecd4fc8",
        VendorName: "CAM",
        Notes: "",
        E2EProjectId: `${uiData.E2EProjectId}`,
        Users: [{ Id: uiData.Users }],
        Config: config,
        DeliveryOptions: null,
        ArtifactAttributes: null,
        AccessLinkURL: "",
        // CloudPlatform: "AWS",
        // MyClient: "myWizard AiOps"
      },
    ],
    __v: 0,
  };
};

export const generateATRSaaSSubmitData = (
  uiData,
  orderUId,
  orderItemUId,
  createdBy
) => {
  const currentTime = moment().format();

  const config = Object.entries(uiData).map((pair) => ({
    Key: pair[0],
    Value: pair[1],
  }));

  return {
    OrderUId: `${orderUId}`,
    OrderItems: [
      {
        ProjectId: `${
          uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
          }`,
        ClientName: uiData.ClientName,
        OrderItemUId: `${orderItemUId}`,
        CreatedBy: createdBy,
        CreatedDate: `${currentTime}`,
        CreateDate: `${currentTime}`,
        ModifiedBy: "",
        ModifiedDate: "",
        ResolvedBy: "",
        ResolvedDate: "",
        Status: "Placed",
        ProductUId: "07443833-aad2-4225-9683-9f8d8c11c3d7",
        VendorName: "CAM",
        Users: [{ Id: uiData.Users }],
        AccessLinkURL: "",
        DeploymentRegion: uiData.DeploymentRegion,
        DryRun: uiData.DryRun,
        TeamsRelayEnabled: uiData.TeamsRelayEnabled,
        TeamsClientID:
          uiData.TeamsRelayEnabled === "true" ? uiData.TeamsClientId : "",
        TeamsClientSecret:
          uiData.TeamsRelayEnabled === "true" ? uiData.TeamsClientSecret : "",
        TeamsBotName:
          uiData.TeamsRelayEnabled === "true" ? uiData.TeamsBotName : "",
        Config: config,
        CustomerEnterpriseID: createdBy,
        DomainContextRoot: uiData.DomainContextRoot,
        WBSEcode: uiData.WBSEcode,
      },
    ],
    __v: 0,
  };
};

export const generateDPSubmitData = (
  uiData,
  orderUId,
  orderItemUId,
  createdBy,
  userOrder,
  isFDSEnabled
) => {
  const currentTime = moment().format();

  const config = Object.entries(uiData).map((pair) => ({
    Key: pair[0],
    Value: pair[1],
  }));
  //trim string for RequestNotes
  const requestNotesIndex = config.findIndex((o) => o.Key === "RequestNotes");
  config[requestNotesIndex].Value.trim().replace(/\s+/g, " ");

  return {
    OrderUId: `${orderUId}`,
    UserOrder: userOrder,
    ClientName: uiData.ClientName,
    EndToEndUId: `${
      uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
      }`,
    DMSId: `${uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId}`,
    DeployRegion: uiData.DeployRegion,
    EntityEventUId: "",
    CloudPlatform: "AWS",
    MyClient: "myWizard AiOps",
    OS: "CentOS",
    FDSEnabled: isFDSEnabled,
    OrderItems: [
      {
        OrderItemUId: `${orderItemUId}`,
        ProjectId: `${
          uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
          }`,
        ProvisionType: 3,
        CreatedBy: createdBy,
        CreatedDate: `${currentTime}`,
        CreateDate: `${currentTime}`,
        ModifiedBy: "",
        ModifiedDate: "",
        ResolvedDate: "",
        ResolvedBy: "",
        Status: "Placed",
        ProductUId: "9c4f3e6a-c2ea-e511-8053-180373e9b33d",
        VendorName: "CAM",
        Message: null,
        Users: [{ Id: uiData.Users }],
        Config: config,
        Notes: "",
        AccessLinkURL: "",
        bundledProv:
          uiData.bundledProv === undefined ? false : uiData.bundledProv,
        appProductUId:
          uiData.appProductUId === undefined ? [] : uiData.appProductUId,
        DevOpsAddonsGroup: uiData.DevOpsAddonsGroup,
        TicketFeed: uiData.TicketFeed,
        ServiceNowUrl: uiData.ServiceNowUrl,
        ServiceNowUserName: uiData.ServiceNowUserName,
        ServiceNowPassword: uiData.ServiceNowPassword,
        TicketMonthlyVolume: uiData.TicketMonthlyVolume,

        // CloudPlatform: "AWS",
        // MyClient: "myWizard AiOps"
      },
    ],
    __v: 0,
  };
};

export const generateGWLSubmitData = (
  uiData,
  orderUId,
  orderItemUId,
  createdBy,
  userOrder,
  isFDSEnabled
) => {
  const currentTime = moment().format();

  const config = Object.entries(uiData).map((pair) => ({
    Key: pair[0],
    Value: pair[1],
  }));
  //trim string for RequestNotes
  const requestNotesIndex = config.findIndex((o) => o.Key === "RequestNotes");
  config[requestNotesIndex].Value.trim().replace(/\s+/g, " ");

  return {
    OrderUId: `${orderUId}`,
    UserOrder: userOrder,
    ClientName: uiData.ClientName,
    EndToEndUId: `${uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
      }`,
    DMSId: `${uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId}`,
    DeployRegion: uiData.DeployRegion,
    EntityEventUId: "",
    CloudPlatform: "AWS",
    MyClient: "myWizard AiOps",
    OS: "CentOS",
    FDSEnabled: isFDSEnabled,
    OrderItems: [
      {
        OrderItemUId: `${orderItemUId}`,
        ProjectId: `${uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
          }`,
        ProvisionType: 3,
        CreatedBy: createdBy,
        CreatedDate: `${currentTime}`,
        CreateDate: `${currentTime}`,
        ModifiedBy: "",
        ModifiedDate: "",
        ResolvedDate: "",
        ResolvedBy: "",
        Status: "Placed",
        ProductUId: "01d30423-cf0c-43f8-b81f-54c115ca8fa0",
        VendorName: "CAM",
        Message: null,
        Users: [{ Id: uiData.Users }],
        Config: config,
        Notes: "",
        AccessLinkURL: "",
        AccessLinkURLAD:"",
        AccessLinkURLATR:"",
        bundledProv:
          uiData.bundledProv === undefined ? false : uiData.bundledProv,
        appProductUId:
          uiData.appProductUId === undefined ? [] : uiData.appProductUId,
        DevOpsAddonsGroup: uiData.DevOpsAddonsGroup,
        TicketFeed: uiData.TicketFeed,
        ServiceNowUrl: uiData.ServiceNowUrl,
        ServiceNowUserName: uiData.ServiceNowUserName,
        ServiceNowPassword: uiData.ServiceNowPassword,
        TicketMonthlyVolume: uiData.TicketMonthlyVolume,

        // CloudPlatform: "AWS",
        // MyClient: "myWizard AiOps"
      },
    ],
    __v: 0,
  };
};

export const generateADSubmitData = (
  uiData,
  orderUId,
  orderItemUId,
  createdBy,
  userOrder,
  isFDSEnabled
) => {
  const currentTime = moment().format();

  const config = Object.entries(uiData).map((pair) => ({
    Key: pair[0],
    Value: pair[1],
  }));
  //trim string for RequestNotes
  const requestNotesIndex = config.findIndex((o) => o.Key === "RequestNotes");
  config[requestNotesIndex].Value.trim().replace(/\s+/g, " ");

  return {
    OrderUId: `${orderUId}`,
    UserOrder: userOrder,
    ClientName: uiData.ClientName,
    EndToEndUId: `${uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
      }`,
    DMSId: `${uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId}`,
    DeployRegion: uiData.DeployRegion,
    EntityEventUId: "",
    CloudPlatform: "AWS",
    MyClient: "myWizard AiOps",
    OS: "CentOS",
    FDSEnabled: isFDSEnabled,
    OrderItems: [
      {
        OrderItemUId: `${orderItemUId}`,
        ProjectId: `${uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
          }`,
        ProvisionType: 3,
        CreatedBy: createdBy,
        CreatedDate: `${currentTime}`,
        CreateDate: `${currentTime}`,
        ModifiedBy: "",
        ModifiedDate: "",
        ResolvedDate: "",
        ResolvedBy: "",
        Status: "Placed",
        ProductUId: "6c7eb40d-d65f-4c55-ae2c-29a06fb370ce",
        VendorName: "CAM",
        Message: null,
        Users: [{ Id: uiData.Users }],
        Config: config,
        Notes: "",
        AccessLinkURL: "",
        bundledProv:
          uiData.bundledProv === undefined ? false : uiData.bundledProv,
        appProductUId:
          uiData.appProductUId === undefined ? [] : uiData.appProductUId,
        DevOpsAddonsGroup: uiData.DevOpsAddonsGroup,
        TicketFeed: uiData.TicketFeed,
        ServiceNowUrl: uiData.ServiceNowUrl,
        ServiceNowUserName: uiData.ServiceNowUserName,
        ServiceNowPassword: uiData.ServiceNowPassword,
        TicketMonthlyVolume: uiData.TicketMonthlyVolume,

        // CloudPlatform: "AWS",
        // MyClient: "myWizard AiOps"
      },
    ],
    __v: 0,
  };
};

export const generateWPSubmitData = (
  uiData,
  orderUId,
  orderItemUId,
  createdBy,
  userOrder,
  isFDSEnabled
) => {
  const currentTime = moment().format();

  const config = Object.entries(uiData).map((pair) => ({
    Key: pair[0],
    Value: pair[1],
  }));
  //trim string for RequestNotes
  const requestNotesIndex = config.findIndex((o) => o.Key === "RequestNotes");
  config[requestNotesIndex].Value.trim().replace(/\s+/g, " ");

  return {
    OrderUId: `${orderUId}`,
    UserOrder: userOrder,
    ClientName: uiData.ClientName,
    EndToEndUId: `${
      uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
      }`,
    DMSId: `${uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId}`,
    DeployRegion: uiData.DeployRegion,
    EntityEventUId: "",
    CloudPlatform: "AWS",
    MyClient: "myWizard AiOps",
    OS: "CentOS",
    FDSEnabled: isFDSEnabled,
    OrderItems: [
      {
        OrderItemUId: `${orderItemUId}`,
        ProjectId: `${
          uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
          }`,
        ProvisionType: 3,
        CreatedBy: createdBy,
        CreatedDate: `${currentTime}`,
        CreateDate: `${currentTime}`,
        ModifiedBy: "",
        ModifiedDate: "",
        ResolvedDate: "",
        ResolvedBy: "",
        Status: "Placed",
        ProductUId: "34cd1x5z-3cv5-23b2-6911-45s2pp3bza99",
        VendorName: "CAM",
        Message: null,
        Users: [{ Id: uiData.Users }],
        Config: config,
        Notes: "",
        AccessLinkURL: "",
        bundledProv:
          uiData.bundledProv === undefined ? false : uiData.bundledProv,
        appProductUId:
          uiData.appProductUId === undefined ? [] : uiData.appProductUId,
        DevOpsAddonsGroup: uiData.DevOpsAddonsGroup,
        TicketFeed: uiData.TicketFeed,
        ServiceNowUrl: uiData.ServiceNowUrl,
        ServiceNowUserName: uiData.ServiceNowUserName,
        ServiceNowPassword: uiData.ServiceNowPassword,
        TicketMonthlyVolume: uiData.TicketMonthlyVolume,

        // CloudPlatform: "AWS",
        // MyClient: "myWizard AiOps"
      },
    ],
    __v: 0,
  };
};

export const generateSplunkClusterSubmitData = (
  uiData,
  orderUId,
  orderItemUId,
  createdBy,
  userOrder,
  isFDSEnabled
) => {
  const currentTime = moment().format();
  //console.log(uiData)
  uiData.StackApplication = [...new Set(uiData.StackApplication)];

  uiData.StackApplication = uiData.StackApplication.toString();
  const config = Object.entries(uiData).map((pair) => ({
    Key: pair[0],
    Value: pair[1],
  }));
  //trim string for RequestNotes
  const requestNotesIndex = config.findIndex((o) => o.Key === "RequestNotes");
  config[requestNotesIndex].Value.trim().replace(/\s+/g, " ");

  return {
    OrderUId: `${orderUId}`,
    UserOrder: userOrder,
    ClientName: uiData.ClientName,
    EndToEndUId: `${
      uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
      }`,
    DMSId: `${uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId}`,
    DeployRegion: uiData.DeployRegion,
    EntityEventUId: "",
    CloudPlatform: "AWS",
    MyClient: "myWizard AiOps",
    OS: "CentOS",
    FDSEnabled: isFDSEnabled,
    OrderItems: [
      {
        OrderItemUId: `${orderItemUId}`,
        ProjectId: `${
          uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
          }`,
        ProvisionType: 3,
        CreatedBy: createdBy,
        CreatedDate: `${currentTime}`,
        CreateDate: `${currentTime}`,
        ModifiedBy: "",
        ModifiedDate: "",
        ResolvedDate: "",
        ResolvedBy: "",
        Status: "Placed",
        ProductUId: "f6c9bf3a-a401-405b-b701-cd9d98afee3d",
        VendorName: "CAM",
        Message: null,
        Users: [{ Id: uiData.Users }],
        Config: config,
        Notes: "",
        AccessLinkURL: "",
        bundledProv:
          uiData.bundledProv === undefined ? false : uiData.bundledProv,
        appProductUId:
          uiData.appProductUId === undefined ? [] : uiData.appProductUId,
        DevOpsAddonsGroup: uiData.DevOpsAddonsGroup,
        TicketFeed: uiData.TicketFeed,
        ServiceNowUrl: uiData.ServiceNowUrl,
        ServiceNowUserName: uiData.ServiceNowUserName,
        ServiceNowPassword: uiData.ServiceNowPassword,
        TicketMonthlyVolume: uiData.TicketMonthlyVolume,

        HighAvailability: uiData.HighAvailability,

        // CloudPlatform: "AWS",
        // MyClient: "myWizard AiOps"
      },
    ],
    __v: 0,
  };
};

export const generateStack360SubmitData = (
  uiData,
  orderUId,
  orderItemUId,
  createdBy,
  userOrder,
  isFDSEnabled
) => {
  const currentTime = moment().format();

  const StackApplicationString = uiData.StackApplication.join();

  let newUiData = uiData;

  newUiData.StackApplication = StackApplicationString;

  const config = Object.entries(newUiData).map((pair) => ({
    Key: pair[0],
    Value: pair[1],
  }));
  //trim string for RequestNotes
  const requestNotesIndex = config.findIndex((o) => o.Key === "RequestNotes");
  config[requestNotesIndex].Value.trim().replace(/\s+/g, " ");

  return {
    OrderUId: `${orderItemUId}`,
    UserOrder: userOrder,
    ClientName: uiData.ClientName,
    EndToEndUId: `${
      uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
      }`,
    DMSId: `${uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId}`,
    DeployRegion: uiData.DeployRegion,
    EntityEventUId: "",
    CloudPlatform: "AWS",
    MyClient: "myWizard AiOps",
    OS: "CentOS",
    FDSEnabled: isFDSEnabled,
    OrderItems: [
      {
        OrderItemUId: `${orderUId}`,
        ProjectId: `${
          uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
          }`,
        ProvisionType: 3,
        CreatedBy: createdBy,
        CreatedDate: `${currentTime}`,
        CreateDate: `${currentTime}`,
        ModifiedBy: "",
        ModifiedDate: "",
        ResolvedDate: "",
        ResolvedBy: "",
        Status: "Placed",
        ProductUId: "d941e736-128b-4102-999d-c3f734369eec",
        VendorName: "CAM",
        Message: null,
        Users: [{ Id: uiData.Users }],
        Config: config,
        Notes: "",
        AccessLinkURL: "",
        bundledProv:
          uiData.bundledProv === undefined ? false : uiData.bundledProv,
        appProductUId:
          uiData.appProductUId === undefined ? [] : uiData.appProductUId,
        DevOpsAddonsGroup: uiData.DevOpsAddonsGroup,
        TicketFeed: uiData.TicketFeed,
        ServiceNowUrl: uiData.ServiceNowUrl,
        ServiceNowUserName: uiData.ServiceNowUserName,
        ServiceNowPassword: uiData.ServiceNowPassword,
        TicketMonthlyVolume: uiData.TicketMonthlyVolume,

        // HighAvailability: uiData.HighAvailability,
        StackApplication: StackApplicationString,
      },
    ],
    __v: 0,
  };
};

export const generateDPIndustrySubmitData = (uiData, orderUId, createdBy) => {
  const currentTime = moment().format();

  return {
    OrderUId: `${orderUId}`,
    ClientId: uiData.ClientId,
    Environment: uiData.Environment,
    ProductId: "",
    ParentProductId: "",
    ClientName: uiData.ClientName,
    CloudPlatform: uiData.CloudPlatform,
    MyClient: "myindustry",
    TentativeServiceEndDate: uiData.TentativeServiceEndDate,
    CustomerEnterpriseID: uiData.CustomerEnterpriseID,
    CustomerMobileNo: uiData.CustomerMobileNo,
    WBSEcode: uiData.WBSEcode,
    DomainContextRoot: uiData.DomainContextRoot,
    GDPR: uiData.GDPR,
    RequestNotes: uiData.RequestNotes,
    OrderItems: [
      {
        CreatedBy: createdBy,
        CreatedDate: `${currentTime}`,
        CreateDate: `${currentTime}`,
        ModifiedBy: "",
        ModifiedDate: "",
        ResolvedDate: "",
        ResolvedBy: "",
        Status: "Placed",
        Config: [
          {
            Key: "RDSMultiAZ",
            Value: uiData.RDSMultiAZ + "",
          },
          {
            Key: "IsMultiZoneDeployemt",
            Value: uiData.IsMultiZoneDeployment + "",
          },
        ],
      },
    ],
    __v: 0,
  };
};

export const generateMPSubmitData = (
  uiData,
  orderUId,
  orderItemUId,
  createdBy,
  userOrder,
  isFDSEnabled
) => {
  const currentTime = moment().format();

  const config = Object.entries(uiData).map((pair) => ({
    Key: pair[0],
    Value: pair[1],
  }));
  //trim string for RequestNotes
  const requestNotesIndex = config.findIndex((o) => o.Key === "RequestNotes");
  config[requestNotesIndex].Value.trim().replace(/\s+/g, " ");
  return {
    OrderUId: `${orderUId}`,

    UserOrder: userOrder,
    ClientName: uiData.ClientName,
    EndToEndUId: `${
      uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
      }`,
    DMSId: `${uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId}`,
    DeployRegion: uiData.DeployRegion,
    FDSEnabled: isFDSEnabled,
    CloudPlatform: "AWS",
    MyClient: "myWizard AiOps",
    OS: "CentOS",
    EntityEventUId: "",
    OrderItems: [
      {
        OrderItemUId: `${orderItemUId}`,
        ProjectId: `${
          uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
          }`,
        ProvisionType: 3,
        CreatedBy: createdBy,
        CreatedDate: `${currentTime}`,
        CreateDate: `${currentTime}`,
        ModifiedBy: "",
        ModifiedDate: "",
        ResolvedDate: "",
        ResolvedBy: "",
        Status: "Placed",
        ProductUId: "55cdb588-c8d5-4f4d-8f9c-0905ee090df9",
        VendorName: "CAM",
        Massage: null,
        Users: [{ Id: uiData.Users }],
        Config: config,
        DeliveryOptions: null,
        ArtifactAttributes: null,
        Notes: "",
        AccessLinkURL: "",
        bundledProv:
          uiData.bundledProv === undefined ? false : uiData.bundledProv,
        appProductUId:
          uiData.appProductUId === undefined ? [] : uiData.appProductUId,
        TicketFeed: uiData.TicketFeed,
        ServiceNowUrl: uiData.ServiceNowUrl,
        ServiceNowUserName: uiData.ServiceNowUserName,
        ServiceNowPassword: uiData.ServiceNowPassword,
        TicketMonthlyVolume: uiData.TicketMonthlyVolume,
        // CloudPlatform: "AWS",
        // MyClient: "myWizard AiOps"
      },
    ],
    __v: 0,
  };
};

export const generatePISubmitData = (
  uiData,
  orderUId,
  orderItemUId,
  createdBy,
  userOrder,
  isFDSEnabled
) => {
  const currentTime = moment().format();

  const config = Object.entries(uiData).map((pair) => ({
    Key: pair[0],
    Value: pair[1],
  }));
  //trim string for RequestNotes
  const requestNotesIndex = config.findIndex((o) => o.Key === "RequestNotes");
  config[requestNotesIndex].Value.trim().replace(/\s+/g, " ");

  return {
    OrderUId: `${orderUId}`,
    UserOrder: userOrder,
    ClientName: uiData.ClientName,
    EndToEndUId: `${
      uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
      }`,
    DMSId: `${uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId}`,
    DeployRegion: uiData.DeployRegion,
    EntityEventUId: "",
    FDSEnabled: isFDSEnabled,
    CloudPlatform: "AWS",
    MyClient: "myWizard AiOps",
    OS: "CentOS",
    OrderItems: [
      {
        OrderItemUId: `${orderItemUId}`,
        ProvisionType: 3,
        CreatedBy: createdBy,
        CreatedDate: `${currentTime}`,
        CreateDate: `${currentTime}`,
        ModifiedBy: "",
        ModifiedDate: "",
        ResolvedDate: "",
        ResolvedBy: "",
        Status: "Placed",
        ProductUId: "5b384be8-62bf-419f-bb10-2ff5287afd71",
        VendorName: "CAM",
        Message: null,
        Notes: "",
        Users: [{ Id: uiData.Users }],
        Config: config,
        DeliveryOptions: null,
        ArtifactAttributes: null,
        AccessLinkURL: "",
        ProjectId: `${
          uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
          }`,
        // CloudPlatform: "AWS",
        // MyClient: "myWizard AiOps"
      },
    ],
    __v: 0,
  };
};

export const generatePAMSubmitData = (
  uiData,
  orderUId,
  orderItemUId,
  createdBy,
  userOrder
) => {
  const currentTime = moment().format();

  const config = Object.entries(uiData).map((pair) => ({
    Key: pair[0],
    Value: pair[1],
  }));
  //trim string for RequestNotes1
  const requestNotesIndex = config.findIndex((o) => o.Key === "RequestNotes");
  config[requestNotesIndex].Value.trim().replace(/\s+/g, " ");

  return {
    OrderUId: `${orderUId}`,
    UserOrder: userOrder,
    ClientName: uiData.ClientName,
    EndToEndUId: `${
      uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
      }`,
    DMSId: `${uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId}`,
    DeployRegion: uiData.DeployRegion,
    InstanceTypeUId: "00000000-0000-0000-0000-000000000000",
    OrderItems: [
      {
        OrderItemUId: `${orderItemUId}`,
        CreatedBy: createdBy,
        CreatedDate: `${currentTime}`,
        CreateDate: `${currentTime}`,
        Status: "Placed",
        ProductUId: "5d3a49da-637e-43bd-a460-6bb329a2b2af",
        VendorName: "CAM",
        Config: config,
        AccessLinkURL: "",
        ProjectId: `${
          uiData.EndToEndUId === "" ? uiData.DMSId : uiData.EndToEndUId
          }`,
        PAMApps: uiData.PAMApps,
        Initiative: "PAM",
      },
    ],
    __v: 0,
  };
};

export const generateAALSubmitData = (uiData, orderUId, orderItemUId, createdBy) => {
  const config = Object.entries(uiData).map((pair) => ({
    Key: pair[0],
    Value: pair[1],
  }));
  //trim string for RequestNotes
  const requestNotesIndex = config.findIndex((o) => o.Key === "RequestNotes");
  config[requestNotesIndex].Value.trim().replace(/\s+/g, " ");

  const currentTime = moment().format();
  const projectName = uiData.ProjectName
  const customProjectName = projectName.replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, '');

  const sapContractIdIndex = config.findIndex((o) => o.Key === "SAPContractID");
  const endToEndUIdIndex = config.findIndex((o) => o.Key === "EndToEndUId");
  if (uiData.SAPContractID === "") {
    config[sapContractIdIndex].Value = customProjectName
  }
  if (uiData.EndToEndUId === "") {
    config[endToEndUIdIndex].Value = customProjectName
  }


  return {
    OrderUId: `${orderUId}`,
    ClientName: uiData.ClientName,
    DMSId: uiData.DMSId === "" ? uiData.EndToEndUId : uiData.DMSId,
    EndToEndUId: uiData.EndToEndUId === "" ? customProjectName : uiData.EndToEndUId,
    FDSEnabled: false,
    DeployRegion: "NA",
    EntityEventUId: "",
    OrderItems: [
      {
        OrderItemUId: `${orderItemUId}`,
        ProvisionType: 3,
        CreatedBy: createdBy,
        CreatedDate: `${currentTime}`,
        CreateDate: `${currentTime}`,
        ModifiedBy: "",
        ModifiedDate: "",
        ResolvedDate: "",
        ResolvedBy: "",
        Status: "Placed",
        ProductUId: "7cd9e4f7-4bc5-e811-b18e-484d7ef53e61",
        VendorName: "CAM",
        Message: null,
        Users: "",
        Notes: uiData.RequestNotes,
        Config: config,
        DeliveryOptions: null,
        ArtifactAttributes: null,
        ProjectId: customProjectName,
        AccessLinkURL: "",
      },
    ],
    __v: 0,
  };
};

export const generateOPSSubmitData = (uiData, orderUId, orderItemUId, createdBy) => {
  const config = Object.entries(uiData).map((pair) => ({
    Key: pair[0],
    Value: pair[1],
  }));
 
  const projectName = uiData.ProjectName
  const customProjectName = projectName.replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, '');

  const sapContractIdIndex = config.findIndex((o) => o.Key === "SAPContractID");
  const endToEndUIdIndex = config.findIndex((o) => o.Key === "EndToEndUId");
  if (uiData.SAPContractID === ""){
    config[sapContractIdIndex].Value = customProjectName
  }
  if (uiData.EndToEndUId === ""){
    config[endToEndUIdIndex].Value = customProjectName
  }

  const currentTime = moment().format();

  return {
    OrderUId: `${orderUId}`,
    ClientName: uiData.ClientName,
    DMSId: uiData.DMSId === "" ? uiData.EndToEndUId : uiData.DMSId,
    EndToEndUId: uiData.EndToEndUId === "" ? customProjectName : uiData.EndToEndUId,
    DeployRegion: uiData.DeployRegion,
    EntityEventUId: "",
    FDSEnabled: uiData.isFDSEnabled,
    OrderItems: [
      {
        OrderItemUId: `${orderItemUId}`,
        ProvisionType: 3,
        CreatedBy: createdBy,
        CreatedDate: `${currentTime}`,
        CreateDate: `${currentTime}`,
        ModifiedBy: "",
        ModifiedDate: "",
        ResolvedDate: "",
        ResolvedBy: "",
        Status: "Placed",
        ProductUId: "d94cb489-8b20-4909-9726-0f37949593f0",
        VendorName: "CAM",
        Message: null,
        Users: "",
        Notes: "",
        Config: config,
        E2EProjectId: uiData.E2EProjectId,
        DeliveryOptions: null,
        ArtifactAttributes: null,
        // ProjectId: uiData.DMSId === "" ? uiData.EndToEndUId : uiData.DMSId,
        ProjectId: uiData.EndToEndUId === "" ? customProjectName : uiData.EndToEndUId,
        AccessLinkURL: "",
      },
    ],
    __v: 0,
  };
};
