import Quasar from "./Quasar";

const priceToTier = {
    bronze: {
      price: "$250",
    },
    silver: {
      price: "$450",
    },
    gold: {
      price: "$650",
    },
    platinum: {
      price: "$1025",
    },
    diamond: {
      price: "$1800",
    },
  };
  const tierToApplicationProvisionMapping={
    bronze: {
         "Quasarpp": "$250",
         "AD": "$250",
         "ATR": "$250",
      },
      silver: {
        "Quasarpp": "$450",
         "AD": "$450",
         "ATR": "$450",
         
      },
      gold: {
        "Quasarpp": "$650",
         "AD": "$650",
         "ATR": "$650",
         
      },
      platinum: {
        "Quasarpp": "$1025",
         "AD": "$1025",
         "ATR": "$1025",
       
      },
      diamond: {
        "Quasarpp": "$1800",
         "AD": "$1800",
         "ATR": "$1800",
        
      }, 
  }
 
  export default{priceToTier,tierToApplicationProvisionMapping};
