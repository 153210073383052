import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as fuiRedux from "../../../redux/fui/index";
import {
  fetchExternalClientDetails,
} from "../../../redux/fui/index";
import { Grid } from "@material-ui/core";
import {
  StyledTooltip,
  TextHelperWrapper,
  TextHelperTypography,
} from "../FuiStyles";
import {
  longTextEndDate,
  rootExistedHint,
  rootWrongFormatHint,
  quickConfigurationTextHelper,
} from "../Constants/LongText";
import { useDebounce } from "../../../helper/customizedHooks";
import { DateTextFieldCommonComponent } from "../DateTextFieldCommonComponent/DateTextFieldCommonComponent";
import * as FormField from "./IndividualFormFields";
import * as PlatformFormField from "./IndividualFormFields";
import priceConfig from "./pricetotierMapping";
import TextsmsIcon from "@material-ui/icons/Textsms";
import {
  checkServiceNowTicket,
  checkWBSECode,
  checkBenefitsExpected,
  checkConcurrentUsers,
  checkDailyDataVolume,
  checkMonthlyTicketVolume,
} from "./CommonFunctions";
import GeneralForm from "./GeneralForm";
import BundleOrderApps from "./BundleOrderApps";
const aSelectedInstanceFields = {
  DMSId: { type: "string", fieldName: "dmsId" },
  ClientName: { type: "string", fieldName: "clientName" },
  DeployRegion: { type: "object", fieldName: "deployRegion" },
  SAPContractID: { type: "object", fieldName: "sapContractId" },
  AaamPlatformType: { type: "object", fieldName: "aaamPlatformType" },
};
/**
 * Dedicated Platform Form
 * @author Isabel Sprengers
 * @version 2.0
 * @see Used in component: PlatformLicenseStepper
 */

const DedicatedPlatform = ({
  values, // the value would be changed by user's input
  updateDPForm, //the function to update DPForm values which is from redux
  updateASelectedInstanceByField,
  createdBy, // user's EID
  checkDomainAvaliable, // the function that check whether DomainContextRoot exist or not from redux
  isRootExist, // the value that show whether DomainContextRoot exist or not from redux
  setIsAllValid, // the function to check whether all validations are satisfieds
  selectedEnvironment,
  minDate,
  maxDate,
  isServiceEndDateInvalid,
  getSelectedEnvironment,
  getServiceEndDate,
  getNotesValue,
  //   getUsersValue,
  //   userValidations,
  notesValidations,
  configurationType,
  classes,
  history,
  email,
  idToken,
  isExternalClientLoading,
  externalClientData,
  externalClientError,
  fetchExternalClientDetails,
  isLoading
}) => {

  // open the dialog when user choose Yes in GDPR
  const config = configurationType === "quick";
  const [isMonthlyTicketVolumeValid, setIsMonthlyTicketVolumeValid] = useState(
    true
  );

  // check whether values are valid
  const [isServiceNowTicketValid, setIsServiceNowTicketValid] = useState(true);
  const [isWBSECodeValid, setIsWBSECodeValid] = useState(true);
  // check whether WBSECode is equal to MD & I WBSE
  const [isWBSECodeMDIValid, setIsWBSECodeMDIValid] = useState(true);
  const [isProjectNameValid, setIsProjectNameValid] = useState(true);
  const [isDailyDataVolumeValid, setIsDailyDataVolumeValid] = useState(true);
  const [isConcurrentUsersValid, setIsConcurrentUsersValid] = useState(true);
  const [isBenefitsExpectedValid, setIsBenefitsExpectedValid] = useState(true);
  //   const [isUsersFieldValid, setUsersField] = useState(false);

  const isGDPR = values.GDPR === "yes";
  const isAAAMTermsConditions = values.AAAMTermsConditions === "yes";
  updateDPForm("ConfigurationType", configurationType);

  const [sapContractCodeLength, setSapContractCodeLength] = useState("0");
  const [domainContextRoot, setDomainContextRoot] = useState("[__]");
  const [regionCode, setRegionCode] = useState("[__]");
  const [isTeamNameSelected, setIsTeamNameSelected] = useState(true);
  const [env, setEnv] = useState("");

  const [customURL, setCustomURL] = useState("https://[__].mywizard-aiops.accenture.com/[__]");

  useEffect(() => {
    if (config) {
      setDomainContextRoot("[__]");
      setRegionCode("[__]");
    }
  }, [config]);

  if (config) {
    updateDPForm("ClientName", "")
    updateDPForm("EndToEndUId", "")
    updateDPForm("Company", "")
    updateDPForm("AvanadeCountry", "")
    updateDPForm("AccentureCountry", "")
  }

  if (!config) {
    if (externalClientData) {
      updateDPForm("ClientName", externalClientData[0])
      updateDPForm("EndToEndUId", externalClientData[1])
      updateDPForm("Company", externalClientData[2])
      updateDPForm("AvanadeCountry", externalClientData[3])
      updateDPForm("AccentureCountry", externalClientData[4])
    }
  }

  // const sapValue = document.getElementById("SAPContractID");
  // if (sapValue) {
  //   if (sapValue.value === "") {
  //     document.getElementById("clientDetails").style.display = "none";
  //     const verifiedIcon = document.getElementById("verifiedIcon")
  //     if (verifiedIcon) {
  //       verifiedIcon.style.display = "none";
  //     }
  //   } else {
  //     document.getElementById("clientDetails").style.display = "block";
  //   }
  // }

  // change validation status
  // (isServiceNowTicketValid ||
  //   isWBSECodeValid ||
  //   isDailyDataVolumeValid ||
  //   isConcurrentUsersValid ||
  //   isBenefitsExpectedValid) &&
  // !isServiceEndDateInvalid
  //   ? setIsAllValid(true)
  //   : setIsAllValid(false);

  if (!config) {
    (isDailyDataVolumeValid ||
      isConcurrentUsersValid ||
      isBenefitsExpectedValid) &&
      !isServiceEndDateInvalid && sapContractCodeLength == 10 && !externalClientError && isWBSECodeValid && isWBSECodeMDIValid && isServiceNowTicketValid && isProjectNameValid && isTeamNameSelected
      ? setIsAllValid(true)
      : setIsAllValid(false);
  } else {
    (isServiceNowTicketValid ||
      isDailyDataVolumeValid ||
      isConcurrentUsersValid ||
      isBenefitsExpectedValid) &&
      !isServiceEndDateInvalid && isWBSECodeValid && isWBSECodeMDIValid && isProjectNameValid && isTeamNameSelected
      ? setIsAllValid(true)
      : setIsAllValid(false);
  }

  // get user's input
  const handleChange = (event, fieldName) => {
    if (fieldName === "TentativeServiceEndDate") {
      updateDPForm(fieldName, event);
      getServiceEndDate(event);
    } else if (fieldName === "TeamName") {
      if (event !== undefined || event !== null) {
        updateDPForm(fieldName, event);
        setIsTeamNameSelected(true);
        if (values.TeamName !== "Others") {
          updateDPForm("TeamNameOthers", "")
        }
      }
    } else {
      const { value } = event.target;
      event.preventDefault();
      updateDPForm(fieldName, value);
      // if (fieldName === "Environment") {
      //   getSelectedEnvironment(value);
      // }
      if (fieldName === "RITM") {
        setIsServiceNowTicketValid(checkServiceNowTicket(value));
      }
      if (fieldName === "WBSEcode") {
        const wbsevalue = value.toUpperCase()
        if (wbsevalue.trim() === "CBYDQ001") {
          setIsWBSECodeMDIValid(false)
        }
        setIsWBSECodeValid(checkWBSECode(value));
      }
      if (fieldName === "ProjectName") {
        if (value.length > 32 || value.length < 3) {
          setIsProjectNameValid(false)
        } else {
          setIsProjectNameValid(true)
        }
      }
      if (fieldName === "DailyDataVolume") {
        if (value[0] === "0") {
          setIsDailyDataVolumeValid(false);
        } else if (parseInt(value, 10) > 200) {
          setIsDailyDataVolumeValid(false);
        } else {
          setIsDailyDataVolumeValid(checkDailyDataVolume(value));
        }
      }
      if (fieldName === "ConcurrentUsers") {
        setIsConcurrentUsersValid(checkConcurrentUsers(value));
      }
      if (fieldName === "BenefitsExpected") {
        if (value[0] === "0") {
          setIsBenefitsExpectedValid(false);
        } else {
          setIsBenefitsExpectedValid(checkBenefitsExpected(value));
        }
      }
      if (fieldName === "RequestNotes") {
        if (value.length > 0 && value.length < 247) {
          getNotesValue({ emptyString: false, maxLength: false });
        } else if (value.length === 0) {
          getNotesValue({ emptyString: true, maxLength: false });
        } else if (value.length > 246) {
          getNotesValue({ emptyString: false, maxLength: true });
        }
      }

      if (fieldName === "TicketMonthlyVolume") {
        if (value[0] === "0") {
          setIsMonthlyTicketVolumeValid(false);
        } else {
          setIsMonthlyTicketVolumeValid(checkMonthlyTicketVolume(value));
        }
      }
      //update aSelectedInstance as other step relies on this data
      if (aSelectedInstanceFields[fieldName]) {
        const afieldDetails = aSelectedInstanceFields[fieldName];
        afieldDetails.type === "string" &&
          updateASelectedInstanceByField({
            field: afieldDetails.fieldName,
            value,
          });
        afieldDetails.type === "object" &&
          updateASelectedInstanceByField({
            field: afieldDetails.fieldName,
            value: {
              Value: value,
            },
          });
      }
      if (fieldName === "SAPContractID") {
        let sapLength = value.length
        setSapContractCodeLength(sapLength)
        if (sapLength == 10) {
          fetchExternalClientDetails(value)
        }
      }
      if (fieldName === "DeploymentRegion") {
        if (value == "sydney") {
          setRegionCode("dh")
        } else if (value == "virginia") {
          setRegionCode("dh1")
        } else if (value == "frankfurt") {
          setRegionCode("dh2")
        } else if (value == "singapore") {
          setRegionCode("dh3")
        } else if (value == "tokyo") {
          setRegionCode("dh4")
        } else if (value == "ireland") {
          setRegionCode("dh5")
        } else if (value == "london") {
          setRegionCode("dh6")
        } else {
          setRegionCode("[__]")
        }
      }
      if (fieldName === "DataClassification") {
        if (value === "Client-Production" || value === "Internal-Production") {
          setEnv("Production")
        } else if (value === "Client-NonProduction" || value === "AppTeam-Testing") {
          setEnv("Test")
        } else if (value === "AppTeam-Development") {
          setEnv("Dev")
        } else {
          setEnv("")
        }
      }

    }
  };

  getSelectedEnvironment(env);
  updateDPForm("Environment", env);

  // user can only input lower case letter and numbers in DomainContextRoot
  const handleChangeRoot = (event, fieldName) => {
    const re = /^[a-z0-9]*$/;
    const value = event.target.value;
    re.test(value) && updateDPForm(fieldName, value);
    setDomainContextRoot(value)
  };

  const handleChangeRootSuggestions = (event, value, fieldName) => {
    const re = /^[a-z0-9]*$/;
    // const value = event.target.value;
    re.test(value) && updateDPForm(fieldName, value);
    setDomainContextRoot(value)
  };

  // it would check whether the root has existed from DB once user stop typing longer than 0.3s
  const debouncedValue = useDebounce(domainContextRoot, 300);
  useEffect(() => {
    debouncedValue && checkDomainAvaliable(debouncedValue);
  }, [debouncedValue]);

  const handleChangeMobileNo = (event, fieldName) => {
    const re = /^[0-9]{0,}$/;
    const value = event.target.value;
    re.test(value) && updateDPForm(fieldName, value);
  };

  return (
    <>
      <GeneralForm
        FormId={7}
        ClientContractFields={
          <div style={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
            >
              {!config &&
                <Grid item xs={4}>
                  {PlatformFormField.SAPContractIDPlatformField(values, handleChange, externalClientError, isExternalClientLoading, sapContractCodeLength)}
                </Grid>}

              {sapContractCodeLength == 10 && sapContractCodeLength != 0 && !isExternalClientLoading && !externalClientError
                ? !config &&
                <Grid item xs={4}> {PlatformFormField.ClientNamePlatformField(values, handleChange, externalClientData[0])}</Grid>
                : ""
              }


              {sapContractCodeLength == 10 && !isExternalClientLoading && !externalClientError ?
                !config &&
                <Grid item xs={4}> {PlatformFormField.EndToEndUIdPlatformField(values, handleChange, externalClientData[1])}</Grid>
                : ""
              }

              <Grid item xs={4}>
                {FormField.ProjectNameField(handleChange, values, isProjectNameValid)}
              </Grid>
              <Grid item xs={4}>
                {FormField.WBSECodeField(handleChange, values, isWBSECodeValid, isWBSECodeMDIValid)}
              </Grid >
              {
                <Grid item xs={4}>
                  {FormField.ServiceNowTicket(handleChange, values, isServiceNowTicketValid)}
                </Grid>}
              <Grid item xs={4}>
                {FormField.SecondaryContact(handleChange, values, config)}
              </Grid><Grid item xs={4}>
                {FormField.DataClassificationField(handleChange, values, configurationType)}
              </Grid>
              <Grid item xs={4}>
                {FormField.TeamNamesField(handleChange, values, isTeamNameSelected)}
              </Grid>
              {values.TeamName === "Others" &&
                <Grid item xs={4}>
                  {FormField.TeamNameOthers(handleChange, values)}
                </Grid >
              }
              <Grid item xs={4}>
                {config &&
                  PlatformFormField.ClientNamePlatformInternal(handleChange, values)}
              </Grid >

              <Grid item xs={12}>
                {PlatformFormField.RequestNotesPlatformField(
                  values,
                  handleChange,
                  notesValidations
                )}
              </Grid>
            </Grid>
          </div>
        }
        EnvironmentFields={
          <div>
            {!config ? <p> Expected Custom URL: <span style={{ color: " #0041F0" }}>https://{regionCode}.genwizardsd.com/{domainContextRoot}</span></p> : ""}
            <div style={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={2}
              >

                <Grid item xs={12}>
                  {PlatformFormField.GDPRPlatformField(
                    values,
                    handleChange,
                    isGDPR,
                    classes
                  )}
                </Grid>

                <Grid item xs={3}>
                  {PlatformFormField.DeploymentRegionPlatformField(
                    values,
                    handleChange,
                    isGDPR
                  )}
                </Grid>

                <Grid item xs={4}>
                  {!config ? PlatformFormField.DomainContextRootPlatformFieldClient(
                    values,
                    handleChangeRootSuggestions,
                    // handleChangeRoot,
                    isRootExist,
                    rootExistedHint,
                    rootWrongFormatHint,
                    externalClientData,
                    domainContextRoot,
                    isLoading
                  ) : PlatformFormField.DomainContextRootPlatformField(
                    values,
                    handleChangeRoot,
                    isRootExist,
                    rootExistedHint,
                    isLoading
                  )}
                </Grid>
                {!config &&
                  <Grid item xs={4}>
                    {PlatformFormField.DailyDataVolumePlatformField(
                      values,
                      handleChange,
                      isConcurrentUsersValid
                    )}
                  </Grid>
                }
                {/* <Grid item xs={3}> */}
                <Grid item xs={3}>
                  {PlatformFormField.EnvironmentField(values, handleChange, env)}
                  {/* {PlatformFormField.EnvironmentPlatformField(values, handleChange)} */}
                </Grid>
                {values.Environment === "Production" && (
                  <>
                    <Grid item xs={3}>
                      {PlatformFormField.TierPlatformField(
                        values,
                        handleChange
                      )}
                      {values.Tier && (
                        <div>
                          <em style={{ fontSize: "small", color: "#0033CC" }}>
                            {/* Cost of {values.Tier} is:{" "} */}
                            Infra & service cost per month:{" "}
                            {priceConfig.priceToTier[values.Tier] &&
                              priceConfig.priceToTier[values.Tier].price}{" "}
                          </em>
                        </div>
                      )}
                    </Grid>
                  </>
                )}

              </Grid>
            </div>
          </div>
        }
        ApplicationFields={
          <div style={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={2}
            >
              <Grid item xs={6}>
                {FormField.AdminDistributionGroupField(handleChange, values)}
              </Grid>
              {!config &&
                <Grid item xs={6}>
                  {FormField.UsersDistributionGroupField(handleChange, values)}
                </Grid>
              }
              <Grid item xs={6}>
                <StyledTooltip
                  title={longTextEndDate}
                  placement="left-start"
                  noValidate
                >
                  <DateTextFieldCommonComponent
                    tentativeServiceEndDate={values.TentativeServiceEndDate}
                    selectedEnvironment={selectedEnvironment}
                    isServiceEndDateInvalid={isServiceEndDateInvalid}
                    minDate={minDate}
                    maxDate={maxDate}
                    handleChange={handleChange}
                  />
                </StyledTooltip>
              </Grid>
              {!config && <Grid item xs={6}></Grid>}
              <Grid item xs={6}>
                <BundleOrderApps
                  classes={classes}
                  handleChange={handleChange}
                  config={config}
                  values={values}
                  updateForm={updateDPForm}
                  isMonthlyTicketVolumeValid={isMonthlyTicketVolumeValid}
                  formName={"dedicated"}
                />
              </Grid>
            </Grid>
          </div>
        }
        history={history}
      ></GeneralForm>
    </>
  );
};

const MSTP = (state, ownProps) => ({
  values: state.fui.DPForm,
  isRootExist: state.fui.isRootExist,
  configurationType: state.fui.configurationType,
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken,
  externalClientData: state.fui.externalClientData,
  externalClientError: state.fui.externalClientError,
  isExternalClientLoading: state.fui.isExternalClientLoading,
  isLoading: state.fui.isLoading,
});

export default connect(MSTP, {
  updateDPForm: fuiRedux.updateDPForm,
  updateASelectedInstanceByField: fuiRedux.updateASelectedInstanceByField,
  checkDomainAvaliable: fuiRedux.checkRootExisted,
  fetchExternalClientDetails,
})(withRouter(DedicatedPlatform));
