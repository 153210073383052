import React, { useState } from "react";

import Axios from "axios";
import base from "../../../config";
import PropTypes from "prop-types";
import Menu from "@material-ui/core/Menu";
import DialogComponent from "./DialogComponent";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import { tokenValidation } from "../../../helper/customizedHooks";
import moment from "moment";
import { DialogActions, CircularProgress } from "@material-ui/core";
import SuccessIcon from "@material-ui/icons/Done";

import {
  Flex,
  PharagraphWrapper,
  Pharagraph,
  StyledTextFieldForm,
  GreenCircularProgress,
} from "../ServerList/styledServerList";
import {
  Subtitle5,
  Subtitle2,
} from "../../common/CommonStyles/CommonTypographyStyles";
import {
  SecondaryButton,
  AcceptButton,
  DeclineButton,
} from "../../common/CommonComponents/Buttons";
import { CommonDialog } from "../../common/CommonComponents/Dialog";
import { connect } from "react-redux";
import { headers } from "../../../helper/api";
import { green } from "@material-ui/core/colors";

/**
 * The ActonItems component is responsible for handling events to stop, start or terminate an instance.
 * @author Carlos Montes
 * @version 1.1
 * @see Used in component: ServerList.js
 */

const ActionItems = ({
  email,
  idToken,
  classes,
  history,
  instance,
  disabled,
  editTags,
  instanceId,
  InstanceType,
  ApplicationName,
  environment,
  serviceEndDate,
  activeAccount,
  setTerminatingIntances,
  initiative,
  downsizeInstanceTypeVal,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(""); // try to run this into one dialog ACCEPT OR NO
  const [isLoadingDownsize, setLoadingDownsize] = useState(""); // try to run this into one dialog ACCEPT OR NO
  const [isLoadingDownsizeError, setLoadingDownsizeError] = useState(""); // try to run this into one dialog ACCEPT OR NO

  const [openDownsizeInstanceTypeDialog, setOpenDownsizeInstanceTypeDialog] = useState(false);
  const [sedDialog, setSedDialog] = useState({
    instanceId: "",
    serviceEndDate: "",
    isServiceEndDateExpired: false,
  }); // dialog to extend the Service End Date when the SED is already expired

  const [minDate, setMinDate] = useState(""); // min value to extend the SED
  const [maxDate, setMaxDate] = useState(""); // max value to extend the SED
  const [currentServiceEndDate, setCurrentServiceEndDate] = useState("");
  const [sed_to_redux, setSed_to_redux] = useState(); // SED format sended to the reducer in order to change the value in the instance
  const [sed_to_api, setSed_to_api] = useState(); // SED format in order to execute the API and change this value in AWS
  const [serviceEndDateNoValid, setServiceEndDateNoValid] = useState(false);

  // add more actions here
  const menuOptions = [
    { displayName: "Start Instance", val: "start" },
    { displayName: "Stop Instance", val: "stop" },
    { displayName: "Terminate", val: "terminate" },
    { displayName: "Enable Splunk SMTP", val: "enablesmtp" },
    { displayName: "Downsize Instance", val: "downsize" },
  ];

  // Handle click on any menuItem
  const handleConnectClick = (event) => {
    setDialogOpen(event); // Open dialog
  };



  // Click in ellipsis menu
  const handleEllipsisMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close ellipsis menu
  const handleActionsMenuClose = () => {
    setAnchorEl(null);
  };

  // Handle click on any menuItem
  const handleOptionClick = (event) => {
    setDialogOpen(event.target.attributes.value.value); // Open dialog
    handleActionsMenuClose(); //close the menu
  };


  // Handle click on downsize instance menu
  const handleDownsizeInstanceClick = (event) => {
    setDialogOpen(event.target.attributes.value.value); // Open dialog
    handleActionsMenuClose(); //close the menu
  };

  // this function obtain the max date for the instance depends on the Instance Environment
  let getMaxDate = (date) => {
    const maxDateEnv = moment()
      .add(date, "days")
      .format("YYYY-MM-DD");
    return maxDateEnv;
  };

  // this function verify if the SED of the instance is already expired
  // and if it is, provide us the correct minDate and maxDate value.
  let sedVerification = () => {
    const currentDate = moment().format("YYYY-MM-DD");
    setMinDate(currentDate);

    // destructuring of the Instance Service end date
    const [
      instance_Day,
      instance_Month,
      instance_Year,
    ] = serviceEndDate.includes("/")
        ? serviceEndDate.split("/")
        : serviceEndDate.split("-");
    setCurrentServiceEndDate(
      `${instance_Year}-${instance_Month}-${instance_Day}`
    );

    let instance_sed = new Date(instance_Year, instance_Month, instance_Day);

    // destructuring of the current service end date
    let current_moment_date = moment().format("YYYY-MM-DD");
    const [
      current_Year,
      current_Month,
      current_Day,
    ] = current_moment_date.split("-");
    let current_sed = new Date(current_Year, current_Month, current_Day);

    // verifying the Instance Environment to get the correct maxDate
    if (environment === "Dev") {
      const endDate = getMaxDate(28);
      setMaxDate(endDate);
    } else if (environment === "Test") {
      const endDate = getMaxDate(14);
      setMaxDate(endDate);
    } else if (environment === "Sales") {
      const endDate = getMaxDate(7305);
      setMaxDate(endDate);
    } else if (environment === "Production") {
      let currentYear = moment().year();
      const endDate = moment(`${currentYear}-12-31`, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      setMaxDate(endDate);
    }

    // verifying if the instance Service End Date is already expired
    if (instance_sed.getTime() < current_sed.getTime()) {
      setServiceEndDateNoValid(true);
      return {
        isServiceEndDateExpired: true,
        instanceId,
        serviceEndDate,
      };
    } else {
      return {
        isServiceEndDateExpired: false,
        instanceId,
        serviceEndDate,
      };
    }
  };

  // Close the SED dialog
  const handleClose = () => {
    setSedDialog({
      instanceId: "",
      serviceEndDate: "",
      isServiceEndDateExpired: false,
    });
    setMaxDate("");
    setCurrentServiceEndDate("");
  };

  // this function will execute an API to start/stop/terminate/downsize an instance
  const handleConfirmAction = (type, downsizeInstanceTypeVal) => {
    switch (type) {
      case "stop":
        handleInstanceActions(
          instanceId,
          email,
          "stop",
          instance.accountId,
          instance.accountType,
          instance.privateDNSName,
          instance.orderid
        );
        //window.location.reload();
        break;
      case "start":
        if (instance.accountId === "161945688208" || instance.accountId === "546363320159") {
          let obj = sedVerification();
          if (obj.isServiceEndDateExpired) {
            setSedDialog(obj);
          } else {
            handleInstanceActions(
              instanceId,
              email,
              "start",
              instance.accountId,
              instance.accountType,
              instance.privateDNSName,
              instance.orderid
            );
          }
        } else {
          handleInstanceActions(
            instanceId,
            email,
            "start",
            instance.accountId,
            instance.accountType,
            instance.privateDNSName,
            instance.orderid
          );
        }
        //window.location.reload();
        break;
      case "terminate":
        handleInstanceActions(
          instanceId,
          email,
          "terminate",
          instance.accountId,
          instance.accountType,
          instance.privateDNSName,
          instance.orderid
        );
        //window.location.reload();
        break;
      case "enablesmtp":
        handleInstanceActions(
          instanceId,
          email,
          "enablesmtp",
          instance.accountId,
          instance.accountType,
          instance.privateDNSName
        );
        //window.location.reload();
        break;
      case "downsize":
        handleDownsizeInstanceAction(
          downsizeInstanceTypeVal,
          instanceId,
          email,
          "downsize",
          instance.accountId,
          instance.accountType,
          instance.privateDNSName,
          instance.instanceType,
          instance.initiative
        );
        //window.location.reload();
        break;
      default:
        console.log("not a valid menu item");
    }
  };

  const url = `${base.urls.apis.ec2}`;
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: idToken,
    },
  };

  const handleInstanceActions = (
    instanceId,
    email,
    action,
    accountId,
    accountType,
    privateDNS,
    orderid
  ) => {
    tokenValidation();
    // const urls = `${url}/${instanceId}/${action}?userepid=${email}&accountId=${accountId}&accountType=${accountType}&privateDNS=${privateDNS}&inititive=${initiative}&orderId=${orderid}`;
    // Axios.get(urls, options).then((response) => {
    //   setTerminatingIntances(instances => [...instances, instanceId])
    // }).catch((error) => {
    //   console.log('Error terminating instance', error)
    // })
    if (ApplicationName === "Klewer and Quasar" || ApplicationName === "genai_Quasar_KM") {
      const urls = `${url}/${instanceId}/${action}?userepid=${email}&accountId=${accountId}&accountType=${accountType}&privateDNS=${privateDNS}&inititive=${initiative}&orderId=None`;

      Axios.get(urls, options).then((response) => {
        setTerminatingIntances(instances => [...instances, instanceId])
      }).catch((error) => {
        console.log('Error terminating instance', error)
      })
    } else if (ApplicationName.startsWith("genai")) {
      const urls = `${url}/${instanceId}/${action}?userepid=${email}&accountId=${accountId}&accountType=${accountType}&privateDNS=${privateDNS}&inititive=${initiative}&orderId=None`;

      Axios.get(urls, options).then((response) => {
        setTerminatingIntances(instances => [...instances, instanceId])
      }).catch((error) => {
        console.log('Error terminating instance', error)
      })
    } else {
      const urls = `${url}/${instanceId}/${action}?userepid=${email}&accountId=${accountId}&accountType=${accountType}&privateDNS=-&inititive=${initiative}&orderId=${orderid}`;

      Axios.get(urls, options).then((response) => {
        setTerminatingIntances(instances => [...instances, instanceId])
      }).catch((error) => {
        console.log('Error terminating instance', error)
      })
    }

  };

  const handleDownsizeInstanceAction = (
    downsizeInstanceTypeVal,
    instanceId,
    email,
    action,
    accountId,
    accountType,
    privateDNS,
    instanceType,
    initiative,
  ) => {
    setOpenDownsizeInstanceTypeDialog(true);
    const idToken = localStorage.getItem("token");
    downsizeInstanceTypeVal = downsizeInstanceTypeVal.split(" ")[0];
    const urls = `${url}/${instanceId}/changeinstancetype?userepid=${email}&accountId=${accountId}&accountType=${accountType}&privateDNS=${privateDNS}&inititive=${initiative}&instancetype=${instanceType}`;
    const selectedInstanceTypeValue = { "newinstancetype": downsizeInstanceTypeVal };
    Axios.post(urls, selectedInstanceTypeValue, {
      headers: { Authorization: idToken },
    }).then((res) => {
      //console.log("Downsize Instance Value",res.status)
    })
      .catch((err) => {
        console.log("Async error", err);
      });
  };


  // these functions are used to deny that the user the ability to
  // put a random value in the ServiceEndDate Dialog
  const onKeyUpFalse = (e) => {
    e.preventDefault();
    return false;
  };
  const onKeyDownFalse = (e) => {
    e.preventDefault();
    return false;
  };

  // this function handle the new service end date Value
  const handleEditKeyValue = (e) => {
    setServiceEndDateNoValid(false);
    const { value } = e.target;
    const [year, month, day] = value.split("-");
    let dateFormat = `${day}/${month}/${year}`;
    setSed_to_api([{ Key: "service_end_date", Value: dateFormat }]);
    setSed_to_redux({ service_end_date: dateFormat });
  };

  const handleOnSubmit = (accountType, privateDNS, accountId) => {
    // Redux Action with the new SED info
    editTags(sed_to_redux, instanceId);

    // api
    let url = `${base.urls.apis.ec2}/${instanceId}/modify_tags?userepid=${email}&accountType=${accountType}&accountId=${accountId}&privateDNS=${privateDNS}&inititive=${initiative}`;
    Axios.post(url, sed_to_api, {
      headers: { Authorization: idToken },
    });

    setSedDialog({
      instanceId: "",
      serviceEndDate: "",
      isServiceEndDateExpired: false,
    });

    // clear the SED useStates
    setMinDate("");
    setMaxDate("");
    setCurrentServiceEndDate("");
    setSed_to_redux({});
    setSed_to_api([]);
    setServiceEndDateNoValid(false);
  };

  const stopOption = (option) =>
    option.displayName === "Stop Instance" && (
      <>
        <MenuItem
          data-cy="stopInstance-cy"
          key={option.val}
          value={option.val}
          onClick={handleOptionClick}
        >
          {option.displayName}
        </MenuItem>
        <Divider />
      </>
    );

  const terminateOption = (option) =>
    option.displayName === "Terminate" && (
      <>
        <MenuItem
          key="terminate"
          value="terminate"
          onClick={handleOptionClick}
        >
          {option.displayName}
        </MenuItem>
        <Divider />
      </>
    );

  const startOption = (option) =>
    option.displayName === "Start Instance" && (
      <>
        <MenuItem
          data-cy="startInstance-cy"
          key={option.val}
          value={option.val}
          onClick={handleOptionClick}
        >
          {option.displayName}
        </MenuItem>
        <Divider />
      </>
    );



  {/*   const enableSplunkSMTPOption = (option) =>
    option.displayName === "Enable Splunk SMTP" && (
      <>
        <MenuItem
          data-cy="enableSMTP-cy"
          key={option.val}
          value={option.val}
          onClick={handleOptionClick}
        >
          {option.displayName}
        </MenuItem>
        
      </>
    ); */}

  const enableDownsizeInstanceOption = (option) =>
    option.displayName === "Downsize Instance" && (
      <>
        <MenuItem
          data-cy="downsizeInstance-cy"
          key={option.val}
          value={option.val}
          onClick={handleDownsizeInstanceClick}
        >
          {option.displayName}
        </MenuItem>

      </>
    );

  return (
    <div style={{ padding: "5% 0% 0% 12%", marginLeft: "20%" }}>
      <SecondaryButton
        onClick={() => handleConnectClick("connect")}
        disabled={InstanceType === "Container" || InstanceType === "EventOps" || ApplicationName === "GenWizard AD" ? true : disabled}
        data-cy="sshConnectButton"
        label={"Connect"}
        className={classes.actionButtonsStyle}
      />

      <SecondaryButton
        aria-label="More"
        aria-owns={Boolean(anchorEl) ? "Vertical-menu" : null}
        aria-haspopup="true"
        onClick={handleEllipsisMenuClick}
        disabled={ApplicationName === "GenWizard AD" || InstanceType === "EventOps" ? true : disabled}
        // disabled={disabled}
        label={"Actions"}
        className={classes.actionButtonsStyle}
      />

      <Menu
        id="Vertical-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleActionsMenuClose}
        disableAutoFocusItem
        PaperProps={{
          style: {
            maxHeight: 230,
          },
        }}
      >
        {menuOptions.map((option, key) => {
          return (
            <div>
              {InstanceType === "Container" ? <div>
                {terminateOption(option)}
              </div> :
                <div key={key} >
                  {instance.instanceId === instanceId &&
                    instance.instanceState === "running" &&
                    instance.hasOwnProperty("chatbotTerminatedStatus") === false ? (
                    <div>
                      {stopOption(option)}
                      {terminateOption(option)}
                      {enableDownsizeInstanceOption(option)}
                      {/*} {enableSplunkSMTPOption(option)} */}
                    </div>
                  ) : instance.instanceId === instanceId &&
                    instance.instanceState === "stopped" &&
                    instance.hasOwnProperty("chatbotTerminatedStatus") === false ? (
                    <div>
                      {startOption(option)}
                      {terminateOption(option)}
                      {enableDownsizeInstanceOption(option)}
                    </div>
                  ) : instance.hasOwnProperty("chatbotTerminatedStatus") &&
                    instance.chatbotTerminatedStatus &&
                    instance.instanceState === "running" ? (
                    <div>
                      {stopOption(option)}
                      {terminateOption(option)}
                    </div>
                  ) : (
                    instance.hasOwnProperty("chatbotTerminatedStatus") &&
                    instance.chatbotTerminatedStatus &&
                    instance.instanceState === "stopped" && (
                      <div>
                        {startOption(option)}
                        {terminateOption(option)}
                      </div>
                    )
                  )}
                </div>
              }
            </div>
          );
        })}
      </Menu>
      <CommonDialog
        open={sedDialog.isServiceEndDateExpired}
        onClose={handleClose}
        title={"Service End Date"}
        textContent={
          "Your instance cannot be started since the ServiceEndDate expired. If you want to start this instance you must extend the ServiceEndDate."
        }
        dialogType={"servers"}
        dialogContent={
          <Flex>
            <PharagraphWrapper>
              <Pharagraph>Service End Date:</Pharagraph>
            </PharagraphWrapper>
            <StyledTextFieldForm
              defaultValue={currentServiceEndDate}
              type="date"
              margin="dense"
              variant="outlined"
              error={serviceEndDateNoValid}
              helperText={serviceEndDateNoValid && "Please select a Valid Date"}
              onChange={handleEditKeyValue}
              inputProps={{
                min: minDate,
                max: maxDate,
                onKeyDown: onKeyDownFalse,
                onKeyUp: onKeyUpFalse,
              }}
            />
          </Flex>
        }
        dialogActions={
          <DialogActions>
            <DeclineButton onClick={handleClose} label={"Disagree"} />
            <AcceptButton
              onClick={() =>
                handleOnSubmit(instance.accountType, instance.privateDNSName, instance.accountId)
              }
              label={"Agree"}
            />
          </DialogActions>
        }
      ></CommonDialog>
      {openDownsizeInstanceTypeDialog && (
        <CommonDialog
          open={openDownsizeInstanceTypeDialog}
          onClose={() => setOpenDownsizeInstanceTypeDialog(false)}
          title={"Downsize Instance"}
          dialogType={"servers"}
          textContent={
            <div style={{ textAlign: "center" }}>
              <div>
                <SuccessIcon
                  style={{
                    color: "#28a745",
                    width: "3em",
                    height: "3em",
                  }}
                />
                <Subtitle2>Your request has been successfully submitted! <br></br> Instance downsize requires a reboot. The instance will be unavailable for few mins. If the server is not back in 10 mins, you can start the server, in the server managment page or reach out to <a href="mailto:myWizAiOps.L3Support@accenture.com">L3 Support Team</a>.</Subtitle2>
              </div>
            </div>
          }
          dialogActions={
            <DialogActions >
              <DeclineButton
                onClick={() => setOpenDownsizeInstanceTypeDialog(false)}
                label={"Close"}
                disabled={false}
              />

            </DialogActions>
          }
        ></CommonDialog>
      )}
      {/* Dialog used for start, stop, ssh, terminate, connect, appUrl actions */}
      {isDialogOpen && (
        <DialogComponent
          isDialogOpen={isDialogOpen}
          handleDialogClose={() => setDialogOpen(false)}
          handleDialogConfirm={(type) => handleConfirmAction(type)}
          handleDownsizeInstanceTypeDialogConfirm={(type, downsizeInstanceTypeVal) => handleConfirmAction(type, downsizeInstanceTypeVal)}
          dialogType={isDialogOpen}
          instance={instance}
          instanceType={instance.instanceType}
          privateDNS={instance.privateDNSName}
          classes={classes}
        />
      )}
    </div>
  );
};

ActionItems.propTypes = {
  /**
   * Current users email.
   */
  email: PropTypes.string,
  /**
   * Selected instance, which will be stopped, started and terminated.
   */
  instance: PropTypes.object,
  /**
   * Boolean that disables these actions when an instance is in its "pending" or "stopping" state
   */
  disabled: PropTypes.bool,
  /**
   * Current instance Id.
   */
  instanceId: PropTypes.string,
  /**
   * Current users Token
   */
  idToken: PropTypes.string,
  /**
   * Property containing the current ServiceEndDate of the instance
   */
  serviceEndDate: PropTypes.string,

  setTerminatingIntances: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    activeAccount: state.auth.activeAccount,
  };
};

// export default connect(mapStateToProps)(EditTags);

export default connect(mapStateToProps, null)(ActionItems);
