import React, { useState, useEffect } from "react";
import Divider from "@material-ui/core/Divider";
import { Grid, CircularProgress, Button, TextField, FormHelperText } from "@material-ui/core";
import { Subtitle3, XSmallHeader, Subtext } from "../common/CommonStyles/CommonTypographyStyles";
import { images } from "../../assets";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router-dom";
import { Footer } from "../common/Footer";
import Axios from "axios";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { tokenValidation } from "../../helper/customizedHooks";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Radio } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import base from "../../config";
import { headers } from "../../helper/api";
import { makeStyles } from "@material-ui/core/styles";
import { CommonDialog } from "../common/CommonComponents/Dialog.js";
import {
  Flex,
  Pharagraph,
  ButtonWrapper,
  PharagraphWrapper,
  StyledTextFieldForm,
} from "../ec2management/ServerList/styledServerList.js";
import { DialogActions, RadioGroup } from "@material-ui/core";
import {
  AcceptButton,
  DeclineButton,
} from "../common/CommonComponents/Buttons";
import { StyledFormControl, StyledSelect, StyledRadioGroup } from "../fui/FuiStyles";
import { MenuItem, InputLabel } from "@material-ui/core";
import moment from "moment";
import teamNames from "./TeamNames.js";
import { dataCategorysClient, dataCategorysInternal } from "./DataCategory.js";
import { isNull } from "lodash";
import InputAdornment from "@material-ui/core/InputAdornment";
/**
 * Server Review page
 * @author shilpa.adikeshava
 * @version 1.0
 */
const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
});
function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    boxShadow: "unset",
    padding: "15px 32px 32px 32px",
  },
  count: {
    margin: "0 0 0 0.3em",
    color: "#ffffff",
    margin: "0 0 0 0.3em",
    fontSize: "1.8rem",
    background: "#7500c0",
    padding: "0.2rem 0.9rem 0.2rem 0.9rem",
    borderRadius: "3rem",
  },
}));

const ServerReview = (email, idToken) => {
  const [loaded, setLoaded] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [modalData, setData] = useState({});
  const [modalStyle] = React.useState(getModalStyle);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [openViewDetailsModal, setOpenViewDetailsModal] = useState(false);
  const [openClientModal, setOpenClientModal] = useState(false);
  const [serverReviewList, setServerReviewList] = useState([]);
  const [newFormValueCollector, setNewFormValueCollector] = useState({});
  const [minDate, setMinDate] = useState(undefined);
  const [maxDate, setMaxDate] = useState(undefined);
  const [currentServiceEndDate, setCurrentServiceEndDate] = useState("");
  const [clientInstance, setClientInstance] = useState(null);
  const classes = useStyles();
  const [clientCheck, setClientCheck] = useState("");
  const [sapContractIDCheck, setSAPContractIDCheck] = useState("");
  const [sapContractId, setSAPContractID] = useState("");
  const [clientName, setClientName] = useState("");
  const [teamName, setTeamName] = useState("");
  const [dataClassification, setDataClassification] = useState("");
  const [env, setEnv] = useState("");
  const [sed, setSed] = useState("");
  const [notes, setNotes] = useState("");
  const [customerEID, setCustomerEID] = useState("");
  const [secPoc, setSecPoc] = useState("");
  const [wbseCode, setWBSECode] = useState("");
  const [useraction, setUserAction] = useState("");
  const [instanceID, setInstanceID] = useState("");
  const [name, setName] = useState("");
  const [region, setRegion] = useState("");
  const [resourceType, setResourceType] = useState("");
  const [sapContractCodeLength, setSapContractCodeLength] = useState("0");
  const [errorClientInstance, setErrorClientInstance] = useState(false);
  const [errorSAPContractIDCheck, setErrorSAPContractIDCheck] = useState(false);
  const [errorSAPContractID, setErrorSAPContractID] = useState(false);
  const [errorClientName, setErrorClientName] = useState(false);
  const [errorTeamName, setErrorTeamName] = useState(false);
  const [errorDataClassification, setErrorDataClassification] = useState(false);
  const [errorWBSECode, setErrorWBSECode] = useState(false);
  const [errorNotes, setErrorNotes] = useState(false);
  const [errorCustomerEID, setErrorCustomerEID] = useState(false);
  const [errorSecPoc, setErrorSecPoc] = useState(false);
  const [errorUserAction, setErrorUserAction] = useState(false);
  const [responseValid, setResponseValid] = useState(false);
  const [loader, setLoader] = useState(false);
  const [openSave, setOpenSave] = useState(false);
  const [openSaveError, setOpenSaveError] = useState(false);
  const supportLink = "https://support.accenture.com/support_portal/?id=iframe_catalog_request&sys_id=7ee09b61131aa600380ddbf18144b032"
  const fetchFuiDetailsUrl = `${base.urls.apis.fulfillment}`;
  const [externalClientName, setExternalClientName] = useState("");
  const [errorExternalClientName, setErrorExternalClientName] = useState(false);
  const [externalClientError, setExternalClientError] = useState(false);
  const [externalClientNameValue, setExternalClientNameValue] = useState("");
  const [externalClientNameFetchLoader, setExternalClientNameFetchLoader] = useState(false);
  const [openTerminationConfirmation, setOpenTerminationConfirm] = useState(false);

  let getMaxDate = (date) => {
    const maxDateEnv = moment()
      .add(date, "days")
      .format("YYYY-MM-DD");
    return maxDateEnv;
  };

  const handleEditFieldChange = (e, field) => {
    let value = e.target.value;

    switch (field) {
      case "serviceEndDate":
        const [year, month, day] = value.split("-");
        let dateFormat = `${day}/${month}/${year}`;
        updatedFormValues = { ...modalData, [field]: dateFormat };
    }
    const updatedFormValues = { ...modalData, [field]: value };
    setNewFormValueCollector(updatedFormValues);
  };

  useEffect(() => {
    const currentDate = moment().format("DD-MM-YYYY");
    if (modalData.serviceEndDate === undefined) {
      let [day, month, year] = currentDate.split("-");
      if (day && month && year) {
        setCurrentServiceEndDate(`${year}-${month}-${day}`);
      }
    } else {
      let [day, month, year] = modalData.serviceEndDate.split("/");

      if (day && month && year) {
        setCurrentServiceEndDate(`${year}-${month}-${day}`);
      }
    }
    setMinDate(currentDate);
    const endDate = getMaxDate(28);
    setMaxDate(endDate);
  }, [modalData, serverReviewList]);

  useEffect(() => {
    let timer;
    if (email && idToken) {
      fetchserverReviewList(email);
      timer = setInterval(() => {
        const apiCall = () => {
          tokenValidation();
          fetchserverReviewList(email);
        };
        apiCall();
      }, 5000);
      return () => clearInterval(timer);
    }
  }, [email, idToken]);

  useEffect(() => {
    if (dataClassification) {
      if (dataClassification === "Client-Production") {
        setEnv("Prod")
      }
      else if (dataClassification === "Client-NonProduction") {
        setEnv("Test")
      }
      else if (dataClassification === "Internal-Production") {
        setEnv("Prod")
      }
      else if (dataClassification === "AppTeam-Development") {
        setEnv("Dev")
      }
      else if (dataClassification === "AppTeam-Testing") {
        setEnv("Test")
      }
      else {
        setEnv(" ")
      }
    }
  }, [dataClassification])

  const fetchserverReviewList = async () => {
    const idToken = localStorage.getItem("token");
    const customerEnterpriseId = email.email.split("@")[0];

    if (customerEnterpriseId && idToken) {
      return Axios.get(
        `${base.urls.apis.serverReview}` + `?username=${customerEnterpriseId}`,
        {
          headers: { ...headers, Authorization: idToken },
        }
      )
        .then((res) => {
          setIsLoading(false);
          const data = res.data;
          setServerReviewList(data);
        })
        .catch((err) => {
          //toast.error("ERROR! Please try again after sometime.");
          console.log("Async error", err);
        });
    }
  };

  const handleClose = () => {
    setData(" ");
    setOpenActionModal(false);
    setOpenSave(false);
    setOpenSaveError(false);
    setClientCheck("");
    setSAPContractIDCheck("");
    setSAPContractID("");
    setDataClassification("");
    setExternalClientName("");
    setClientName("");
    setTeamName("");
    setErrorClientInstance(false);
    setErrorSAPContractIDCheck(false);
    setErrorSAPContractID(false);
    setErrorExternalClientName(false);
    setErrorClientName(false);
    setErrorTeamName(false);
    setErrorSecPoc(false);
    setErrorUserAction(false);
    setResponseValid(false);
  };

  const onKeyUpFalse = (e) => {
    e.preventDefault();
    return false;
  };

  const onKeyDownFalse = (e) => {
    e.preventDefault();
    return false;
  };

  const handleEdit = (row) => {
    setData(row);
    setOpenActionModal(true);

    setInstanceID(row.resource_id)
    setName(row.name)
    setRegion(row.region)
    setResourceType(row.resource_type)
    setClientInstance(row.clientInstance)
    setClientName(row.client_name)
    setTeamName(row.teamName)
    setDataClassification(row.dataClassification)
    setEnv(row.environment)
    setWBSECode(row.wbsecode)
    setSed(row.serviceEndDate)
    setNotes(row.notes)
    setCustomerEID(row.customerEnterpriseID)
    setSecPoc(row.secondaryPOC)
    setUserAction("")
    setClientInstance(row.clientInstance)

  };

  const handleViewDetails = (row) => {
    setData(row);
    setOpenViewDetailsModal(true);
  }

  const handleCloseViewDetails = () => {
    setOpenViewDetailsModal(false);
  }

  const handleUpdates = (value, fieldName) => {

    if (fieldName === "clientinstance") {
      if (value == "Yes") {
        setClientCheck(value);
        setTeamName("-")
        setErrorClientInstance(false)
      } else if (value == "No") {
        setClientCheck(value);
        setSAPContractIDCheck("No");
        setExternalClientName("-");
        setErrorClientInstance(false);
      } else {
        setClientCheck("")
        setSAPContractIDCheck("");
        setErrorClientInstance(true);
      }
    } else if (fieldName === "sapcontractidcheck") {
      if (value == "Yes") {
        setSAPContractIDCheck(value);
        setErrorSAPContractIDCheck(false)
      } else if (value == "No") {
        setSAPContractIDCheck(value);
        setSAPContractID("-");
        setExternalClientNameValue("-");
        setErrorSAPContractIDCheck(false);
      } else {
        setSAPContractIDCheck("-");
        setErrorSAPContractIDCheck(true);
      }
    } else if (fieldName === "sapcontractid") {
      let sapLength = value.length
      setSapContractCodeLength(sapLength)
      if (sapLength == 10) {
        setSAPContractID(value);
        fetchExternalClientDetails(value)
        setErrorSAPContractID(false);
      } else if (externalClientNameFetchLoader || externalClientError) {
        setExternalClientName("-");
        setErrorSAPContractID(true);
      } else {
        setSAPContractID(modalData.sap_contract_id);
        setExternalClientName("-");
        setErrorSAPContractID(true);
      }
    } else if (fieldName === "dataclassification") {
      if (value.length > 0) {
        setDataClassification(value);
        setErrorDataClassification(false)
      } else {
        setDataClassification("-");
        setErrorDataClassification(true);
      }
    } else if (fieldName === "clientname") {
      if (value.length > 0) {
        setClientName(value);
        setErrorClientName(false)
      } else {
        setClientName(modalData.client_name);
        setErrorClientName(true);
      }
    } else if (fieldName === "externalclientname") {
      if (externalClientName.length > 0) {
        setExternalClientName(value);
        setExternalClientError(false);
      } else if (externalClientNameFetchLoader || externalClientError) {
        setExternalClientName("");
      } else {
        setExternalClientName("");
        setExternalClientError(true);
      }
    } else if (fieldName === "teamname") {
      if (value.length > 0 || value !== "undefined" || value !== "" || value !== " ") {
        setTeamName(value);
        setErrorTeamName(false)
      } else {
        setTeamName("");
        setErrorTeamName(true);
      }
    } else if (fieldName === "wbsecode") {
      if (value.length > 0 || value !== "undefined" || value !== "" || value !== "-") {
        setWBSECode(value);
        setErrorWBSECode(false)
      } else {
        setWBSECode("");
        setErrorWBSECode(true);
      }
    } else if (fieldName === "serviceenddate") {
      if (value.length > 0) {
        setSed(value)
      } else {
        setSed(modalData.serviceEndDate)
      }
    } else if (fieldName === "notes") {
      if (value.length > 0 || value !== "undefined" || value !== "" || value !== "-") {
        setNotes(value);
        setErrorNotes(false)
      } else {
        setNotes(modalData.notes);
        setErrorNotes(true);
      }
    } else if (fieldName === "customereid") {
      if (value.length > 0 || value !== "undefined" || value !== "" || value !== "-") {
        setCustomerEID(value)
        setErrorCustomerEID(false)
      } else {
        setCustomerEID("")
        setErrorCustomerEID(true)
      }
    } else if (fieldName === "secpoc") {
      if (value.length > 0) {
        setSecPoc(value)
        setErrorSecPoc(false)
      } else {
        setSecPoc("")
        setErrorSecPoc(true)
      }
    } else if (fieldName === "useraction") {
      if (value === "Continue") {
        setUserAction(value)
        setErrorUserAction(false);
      } else if (value === "Terminate") {
        setOpenTerminationConfirm(true)
        setUserAction(value)
        setErrorUserAction(false);
      } else if (value === "undefined") {
        setUserAction("undefined");
        // setErrorUserAction(true);
      } else {
        setUserAction("undefined");
        setErrorUserAction(true);
      }
    }
  };

  const handleValidation = () => {

    if (clientCheck === "undefined" || clientCheck === "") {
      setErrorClientInstance(true)
      setResponseValid(false)
    } else if (clientCheck === "Yes" && (sapContractIDCheck === "undefined" || sapContractIDCheck === "")) {
      setErrorSAPContractIDCheck(true);
      setResponseValid(false)
    } else if (clientCheck === "Yes" && sapContractIDCheck === "Yes" && (sapContractId === "undefined" || sapContractId === "" || sapContractId === "-")) {
      setErrorSAPContractID(true)
      setResponseValid(false)
    } else if (clientCheck === "Yes" && sapContractIDCheck === "Yes" && (externalClientName === "undefined" || externalClientName === "" || externalClientName === "-")) {
      setErrorExternalClientName(true)
      setResponseValid(false)
    } else if (clientCheck === "Yes" && sapContractIDCheck === "No" && (clientName === "undefined" || clientName === "" || clientName === "-")) {
      setErrorClientName(true)
      setResponseValid(false) 
    } else if (clientCheck === "No" && (teamName === "undefined" || teamName === "" || teamName === "-")) {
      setErrorTeamName(true)
      setResponseValid(false)
    } else if (dataClassification === "undefined" || dataClassification === "" || dataClassification === "-") {
      setErrorDataClassification(true)
      setResponseValid(false)
    } else if (wbseCode === "-" || wbseCode === "" || wbseCode === "undefined") {
      setErrorWBSECode(true)
      setResponseValid(false)
    } else if (notes === "-" || notes === "" || notes === "undefined") {
      setErrorNotes(true)
      setResponseValid(false)
    } else if (customerEID === "-" || customerEID === "" || customerEID === "undefined") {
      setErrorCustomerEID(true)
      setResponseValid(false)
    } else if (secPoc === "-" || secPoc === "" || secPoc === "undefined") {
      setErrorSecPoc(true)
      setResponseValid(false)
    } else if (useraction === "undefined" || useraction === "" || useraction === "-") {
      setErrorUserAction(true)
      setResponseValid(false)
    } else {      
      setResponseValid(true)
      setErrorClientInstance(false)
      setErrorSAPContractIDCheck(false);
      setErrorSAPContractID(false)
      setErrorClientName(false)
      setErrorTeamName(false)
      setErrorDataClassification(false)
      setErrorWBSECode(false)
      setErrorNotes(false)
      setErrorCustomerEID(false)
      setErrorSecPoc(false)
      setErrorUserAction(false)
      setOpenSave(true)
      handleSaveResponse()
    }
  }

  const handleSaveResponse = () => {
    setLoader(true);
    const idToken = localStorage.getItem("token");
    const customerEnterpriseId = email.email.split("@")[0];
    const url = `${base.urls.apis.serverReview}?username=${customerEnterpriseId}`;
    const update_url = `${base.urls.apis.serverReview}/update-data?instanceid=${instanceID}`;

    // let instanceValue = instance == null ? "undefined" : instance

    let tag_list = [
      { Key: "Environment", Value: env },
      { Key: "service_end_date", Value: sed },
      { Key: "CustomerEnterpriseID", Value: customerEID },
      { Key: "notes", Value: notes },
      { Key: "SecondaryPOC", Value: secPoc },
      { Key: "clientName", Value: clientName },
      { Key: "WBSEcode", Value: wbseCode },
      { Key: "DataClassification", Value: dataClassification }
    ]

    //Save the data in the excel file in S3
    Axios.post(url, " ", {
      headers: { Authorization: idToken },
      params: {
        "resource_id": instanceID,
        "name": name,
        "region": region,
        "resource_type": resourceType,
        "client_instance": clientCheck,
        "sap_contract_id_check": sapContractIDCheck,
        "sap_contract_id": sapContractId,
        "client_name": clientName,
        "external_client_name": externalClientName,
        "team_name": teamName,
        "data_classification": dataClassification,
        "environment": env,
        "wbse_code": wbseCode,
        "service_enddate": sed,
        "notes": notes,
        "customer_eid": customerEID,
        "sec_poc": secPoc,
        "user_action": useraction,
      }
    }).then((res) => {
      // console.log("res::,res")
      setLoader(false)
    }).catch((err) => {
      console.log("Async error", err);
      setLoader(false)
      setOpenSave(false);
      setOpenSaveError(true);
    });

    //Update the data in the instances tag
    Axios.post(update_url, tag_list, {
      headers: { Authorization: idToken },
    }).then((res) => {
      // console.log("res::,res")
      setLoader(false)
    }).catch((err) => {
      console.log("Async error", err);
      setLoader(false)
      // setOpenSave(false);
      // setOpenSaveError(true);
    });


  }

  const fetchExternalClientDetails = (contractCode) => {
    const idToken = localStorage.getItem("token");
    const urls = `${fetchFuiDetailsUrl}/client-details`;
    setExternalClientNameFetchLoader(true);

    Axios
      .get(urls, {
        headers: {
          ...headers,
          Authorization: idToken,
        },
        params: {
          contract_code: contractCode,
        },
      })
      .then((res) => {
        const { data } = res;
        if (isNull(data[0]) || data[0] === "N/A") {
          setExternalClientError(true);
          setExternalClientNameFetchLoader(false);
        } else {
          setExternalClientName(data[0]);
          setExternalClientError(false);
          setExternalClientNameFetchLoader(false);
        }

      })
      .catch((err) => {
        console.log("Error::", err);
        setExternalClientNameFetchLoader(false);
      });
  };

  return (
    <div>
      <div className="mainDiv">
        <Grid className="headerItems" container>
          <Grid item md={3}>
            <img
              alt=""
              src={images.NavLogoImage}
              // width="70%"
              style={{ float: "left" }}
            />
          </Grid>
          <Grid item md={9} style={{ paddingTop: "1%", paddingBottom: "1%" }}>
            <XSmallHeader
              className="recomHeader"
              style={{ textAlign: "left", color: "#2e2e2e", fontWeight: "500", float: "right" }}
            >
              {" "}
              Server Review{" "}
              {serverReviewList.length > 0 ? (
                <span className={classes.count}>{serverReviewList.length}</span>
              ) : (
                ""
              )}
            </XSmallHeader>
            <ToastContainer />
          </Grid>
        </Grid>
        <Grid>
          <h3 style={{ fontWeight: "500", display: "flex", justifyContent: "end" }}>Review servers to ensure governance and enhance data and lifecycle management</h3>
          <h5 style={{ fontWeight: "300", display: "flex", justifyContent: "end" }}>Note: The deadline to complete this activity is February 28th. Please ensure all necessary steps are taken by this date.</h5>
        </Grid>
        <Divider />
      </div>
      <div>
        <br></br>
        <div>
          {isLoading ? (
            <div
              id="loader"
              style={{
                paddingTop: "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={40} />
            </div>
          ) : (
            <div>
              <div id="myservers">
                <br></br>
                <br></br>
                {serverReviewList && serverReviewList.length > 0 ? (
                  <div>
                    <Paper className={styles.root}>
                      <div
                        className={styles.tableWrapper}
                        style={{
                          background: "#f2f2f2",
                          maxWidth: "95% !important",
                        }}
                      >
                        <Table className={styles.table}>
                          <TableHead style={{ backgroundColor: "#7500C0" }}>
                            <TableRow>
                              <TableCell
                                style={{ color: "#fff", padding: "12px" }}
                              >
                                Resource ID
                              </TableCell>
                              {/* <TableCell
                                style={{ color: "#fff", padding: "12px" }}
                              >
                                Client
                              </TableCell> */}
                              {/* <TableCell
                                style={{ color: "#fff", padding: "12px" }}
                              >
                                Environment
                              </TableCell> */}
                              <TableCell
                                style={{ color: "#fff", padding: "12px" }}
                              >
                                Service End Date
                              </TableCell>
                              {/* <TableCell
                                style={{ color: "#fff", padding: "12px" }}
                              >
                                Application Name
                              </TableCell> */}
                              <TableCell
                                style={{ color: "#fff", padding: "12px" }}
                              >
                                Notes
                              </TableCell>
                              <TableCell
                                style={{ color: "#fff", padding: "12px" }}
                              >
                                Customer Enterprise ID
                              </TableCell>
                              <TableCell
                                style={{ color: "#fff", padding: "12px" }}
                              >
                                WBSE Code
                              </TableCell>
                              <TableCell
                                style={{ color: "#fff", padding: "12px" }}
                              >
                                Action
                              </TableCell>
                              <TableCell
                                style={{ color: "#fff", padding: "12px" }}
                              >
                                Status
                              </TableCell>
                              <TableCell
                                style={{ color: "#fff", padding: "12px" }}
                              >
                                Last Reviewed on
                              </TableCell>
                            </TableRow>
                          </TableHead>

                          {serverReviewList.map((row, index) => (
                            <TableRow
                              key={index}
                              style={{ backgroundColor: "#fff" }}
                            >
                              <TableCell
                                align="left"
                                style={{ padding: "12px" }}
                              >
                                {row.resource_id}
                              </TableCell>
                              {/* <TableCell
                                align="left"
                                style={{ padding: "12px" }}
                              >
                                <RadioGroup
                                  row
                                  onChange={(e) =>
                                    handleRadioChange(e.target.value, row)
                                  }
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    control={<Radio />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={<Radio />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </TableCell> */}

                              {/* Other table cells remain unchanged */}
                              {/* <TableCell
                                align="left"
                                style={{ padding: "12px" }}
                              >
                                {row.environment}
                              </TableCell> */}
                              <TableCell
                                align="left"
                                style={{ padding: "12px" }}
                              >
                                {row.serviceEndDate}
                              </TableCell>
                              {/* <TableCell
                                align="left"
                                style={{ padding: "12px" }}
                              >
                                {row.applicationName}
                              </TableCell> */}
                              <TableCell
                                align="left"
                                style={{ padding: "12px" }}
                              >
                                {row.notes}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "12px" }}
                              >
                                {row.customerEnterpriseID}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "12px" }}
                              >
                                {row.wbsecode}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ padding: "12px" }}
                              >
                                {row.userAction == "Continue" || row.userAction == "Terminate" ? (
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <Grid item style={{ cursor: "pointer" }}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleViewDetails(row)}
                                        style={{ fontSize: "0.875rem" }}
                                      >
                                        VIEW DETAILS
                                      </Button>
                                    </Grid>
                                  </Grid>
                                ) : (
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <Grid item style={{ cursor: "pointer" }}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleEdit(row)}
                                        style={{ fontSize: "0.875rem" }}
                                      >
                                        ATTEST
                                      </Button>
                                    </Grid>
                                  </Grid>
                                )}
                              </TableCell>
                              {row.userAction === "Pending" ?
                                <TableCell
                                  align="left"
                                  style={{ padding: "12px" }}
                                >
                                  <span
                                    style={{ padding: "6px", color: "rgb(226 149 0)" }}
                                  >
                                    Review Pending
                                  </span>
                                </TableCell>
                                :
                                <TableCell
                                  align="left"
                                  style={{ padding: "12px" }}
                                >
                                  <span
                                    style={{ padding: "6px", color: "rgb(4 169 4)" }}
                                  >
                                    Reviewed
                                  </span>
                                </TableCell>
                              }
                              <TableCell
                                align="left"
                                style={{ padding: "12px" }}
                              >
                                {row.lastReviewed}
                              </TableCell>
                            </TableRow>
                          ))}
                        </Table>
                      </div>
                    </Paper>
                  </div>
                ) : (
                  <div style={{ alignContent: "left" }}>
                    <h3
                      style={{
                        textAlign: "left",
                        fontFamily: "Graphik",
                        paddingTop: "10px",
                        fontWeight: "500",
                      }}
                    >
                      There are no pending approvals for you to review!
                    </h3>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />

      <div>
        <CommonDialog
          open={openSave}
          onClose={() => handleClose()}
          title={"Save Response"}
          dialogType={"ssh"}
          dialogContent={
            loader ? (
              <div
                style={{
                  padding: "8%",
                  // paddingBottom: "8%",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={40} />
              </div>
            ) : (
              <div
                style={{
                  padding: "8%",
                  // paddingBottom: "8%",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <h4>Your response is saved successfully!</h4>
                <Flex style={{ justifyContent: "center", paddingTop: "3%" }}>
                  <AcceptButton
                    onClick={() => handleClose()}
                    label={"OK"}
                  />
                </Flex>
              </div>
            )

          }
        />
      </div>

      <div>
        <CommonDialog
          open={openSaveError}
          onClose={() => handleClose()}
          title={"Save Response"}
          dialogType={"ssh"}
          dialogContent={
            <div
              style={{
                padding: "8%",
                // paddingBottom: "8%",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <h4>Oops! An error occurred while saving your response. Please try again later.</h4>
              <Flex style={{ justifyContent: "center", paddingTop: "3%" }}>
                <AcceptButton
                  onClick={() => handleClose()}
                  label={"OK"}
                />
              </Flex>
            </div>
          }
        />
      </div>

      {/* Edit Details Modal */}
      <div>
        <CommonDialog
          open={openActionModal}
          onClose={() => handleClose()}
          title={"EDIT - " + modalData.resource_id}
          dialogType={"servers"}
          dialogContent={
            modalData && (
              <div>
                <Flex>
                  <PharagraphWrapper style={{ textWrap: "nowrap" }}>
                    <Pharagraph>Is this a Client Instance?</Pharagraph>
                  </PharagraphWrapper>
                  <StyledFormControl variant="outlined" margin="dense" error={errorClientInstance}>
                    <StyledRadioGroup
                      onChange={(e) =>
                        handleUpdates(e.target.value, "clientinstance")
                      }
                    // defaultValue={modalData.clientInstance}
                    >
                      <Flex style={{ display: "flex", justifyContent: "start", marginLeft: "17%" }}>
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </Flex>
                    </StyledRadioGroup>
                    {errorClientInstance ? <FormHelperText style={{ margin: "0px 0px 0px 50px" }}>Please select an option</FormHelperText> : ""}
                  </StyledFormControl>

                </Flex>
                {
                  clientCheck == "Yes" ?
                    <Flex>
                      <PharagraphWrapper style={{ textWrap: "nowrap" }}>
                        <Pharagraph>Do you have the SAP Contract ID?</Pharagraph>
                      </PharagraphWrapper>
                      <StyledFormControl variant="outlined" margin="dense" error={errorSAPContractIDCheck}>
                        <StyledRadioGroup
                          onChange={(e) =>
                            handleUpdates(e.target.value, "sapcontractidcheck")
                          }
                        >
                          <Flex style={{ display: "flex", justifyContent: "start", marginLeft: "13%" }}>
                            <FormControlLabel
                              value="Yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="No"
                              control={<Radio />}
                              label="No"
                            />
                          </Flex>
                        </StyledRadioGroup>
                        {errorSAPContractIDCheck ? <FormHelperText style={{ margin: "0px 0px 0px 50px" }}>Please select an option</FormHelperText> : ""}
                      </StyledFormControl>
                    </Flex>
                    : ""}

                {sapContractIDCheck == "Yes" && (sapContractIDCheck !== "" || clientCheck == "No") ?
                  <Flex>
                    <PharagraphWrapper>
                      <Pharagraph>SAP Contract ID:</Pharagraph>
                    </PharagraphWrapper>
                    <StyledTextFieldForm
                      width={"100%"}
                      defaultValue={modalData.sap_contract_id == "undefined" ? "" : modalData.sap_contract_id}
                      label="SAP Contract ID"
                      margin="dense"
                      variant="outlined"
                      onChange={(e) => handleUpdates(e.target.value, "sapcontractid")}
                      InputProps={externalClientNameFetchLoader ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress size={24} />
                          </InputAdornment>
                        ),
                      } : ""}
                      error={sapContractCodeLength !== 10 && sapContractCodeLength > 0 ? true : externalClientError || errorSAPContractID ? true : false}
                      helperText={
                        sapContractCodeLength !== 10 && sapContractCodeLength > 0 || errorSAPContractID ? "Please enter 10 digit valid SAP Contract ID"
                          : externalClientError == false ? "" :
                            "Unable to verify the SAP contract ID. Please select 'No' and provide the client name."
                      }
                    />

                  </Flex> : ""}

                {sapContractCodeLength == 10 && sapContractCodeLength != 0 && !externalClientError && externalClientName && sapContractIDCheck == "Yes" ?
                  <Flex>
                    <PharagraphWrapper>
                      <Pharagraph>Client Name:</Pharagraph>
                    </PharagraphWrapper>
                    <StyledTextFieldForm
                      width={"100%"}
                      // defaultValue={externalClientName}
                      value={externalClientName}
                      label="Client Name"
                      // customerenterpriseidcy="Client_Name"
                      margin="dense"
                      variant="outlined"
                      onChange={(e) => handleUpdates(e.target.value, "externalclientname")}
                      InputProps={{
                        readOnly: true,
                      }}
                    />

                  </Flex>
                  : ""}


                {clientCheck == "Yes" && sapContractIDCheck == "No" ?
                  <Flex>
                    <PharagraphWrapper>
                      <Pharagraph>Client Name:</Pharagraph>
                    </PharagraphWrapper>
                    <StyledTextFieldForm
                      width={"100%"}
                      defaultValue={modalData.client_name == "undefined" ? "" : modalData.client_name}
                      label="Client Name"
                      // customerenterpriseidcy="Client_Name"
                      margin="dense"
                      variant="outlined"
                      onChange={(e) => handleUpdates(e.target.value, "clientname")}
                      helperText={
                        errorClientName && "Please enter the client name"
                      }
                      error={errorClientName}
                    />
                  </Flex> : ""
                }

                {clientCheck == "No" ?
                  <Flex>
                    <PharagraphWrapper>
                      <Pharagraph>Team Name:</Pharagraph>
                    </PharagraphWrapper>
                    <StyledFormControl
                      variant="outlined"
                      margin="dense"
                      style={{ marginLeft: "25%" }}
                      error={errorTeamName}
                    >
                      <InputLabel htmlFor="outlined-service-group">
                        Team Name
                      </InputLabel>
                      <StyledSelect
                        onChange={(e) => handleUpdates(e.target.value, "teamname")}
                        label="Team Name"
                        margin="dense"
                        variant="outlined"
                        defaultValue={modalData.teamName}
                      >
                        <MenuItem value="">
                          <em>--Select Team Name--</em>
                        </MenuItem>
                        {teamNames.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                      {/* {`errorTeamName: ${errorTeamName}`} */}

                      {errorTeamName ? <FormHelperText>Please select a team name</FormHelperText> : ""}
                    </StyledFormControl>

                  </Flex>
                  : ""
                }

                <Flex>
                  <PharagraphWrapper>
                    <Pharagraph>Data Classification:</Pharagraph>
                  </PharagraphWrapper>
                  <StyledFormControl
                    variant="outlined"
                    margin="dense"
                    style={{ width: "50%" }}
                    error={errorDataClassification}
                  >
                    <InputLabel htmlFor="outlined-service-group">
                      Data Classification
                    </InputLabel>
                    <StyledSelect
                      onChange={(e) => handleUpdates(e.target.value, "dataclassification")}
                      label="Data Classification"
                      margin="dense"
                      variant="outlined"
                      defaultValue={modalData.dataClassification}
                    >
                      <MenuItem value="">
                        <em>--Select Data Classification--</em>
                      </MenuItem>
                      {clientCheck === "Yes" ?
                        dataCategorysClient.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        )) : dataCategorysInternal.map((item) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.label}
                          </MenuItem>
                        ))}
                    </StyledSelect>

                    {errorDataClassification ? <FormHelperText>Please select a Data Classification</FormHelperText> : <FormHelperText>Classification of the type of data stored in this instance is crucial for ensuring proper categorization and identification.</FormHelperText>}
                  </StyledFormControl>
                </Flex>

                <Flex>
                  <PharagraphWrapper>
                    <Pharagraph>Environment:</Pharagraph>
                  </PharagraphWrapper>
                  <StyledTextFieldForm
                    label="Environment"
                    width={"100%"}
                    customerenterpriseidcy="environment"
                    value={env}
                    margin="dense"
                    variant="outlined"
                    // onChange={(e) =>
                    //   handleUpdates(e.target.value, "environment")
                    // }
                    disabled={true}
                  />
                </Flex>
                <Flex>
                  <PharagraphWrapper>
                    <Pharagraph>WBSE Code:</Pharagraph>
                  </PharagraphWrapper>
                  <StyledTextFieldForm
                    label="WBSE Code"
                    width={"100%"}
                    customerenterpriseidcy="wbse"
                    defaultValue={modalData.wbsecode}
                    margin="dense"
                    variant="outlined"
                    onChange={(e) => handleUpdates(e.target.value, "wbsecode")}
                    helperText={
                      errorWBSECode && "Please enter a WBSE Code"
                    }
                    error={errorWBSECode}
                  />
                </Flex>
                <Flex>
                  <PharagraphWrapper>
                    <Pharagraph>Service End Date:</Pharagraph>
                  </PharagraphWrapper>
                  <StyledTextFieldForm
                    defaultValue={currentServiceEndDate}
                    type="date"
                    margin="dense"
                    variant="outlined"
                    onChange={(e) =>
                      handleUpdates(e.target.value, "serviceenddate")
                    }
                    inputProps={{
                      min: minDate,
                      max: maxDate,
                      onKeyDown: onKeyDownFalse,
                      onKeyUp: onKeyUpFalse,
                    }}
                  />
                </Flex>
                <Flex>
                  <PharagraphWrapper>
                    <Pharagraph>Notes:</Pharagraph>
                  </PharagraphWrapper>
                  <StyledTextFieldForm
                    label="Notes"
                    width={"100%"}
                    customerenterpriseidcy="notes"
                    defaultValue={modalData.notes}
                    margin="dense"
                    variant="outlined"
                    onChange={(e) => handleUpdates(e.target.value, "notes")}
                    helperText={
                      errorNotes && "Please enter a note"
                    }
                    error={errorNotes}
                  />
                </Flex>
                <Flex>
                  <PharagraphWrapper>
                    <Pharagraph>Customer Enterprise ID:</Pharagraph>
                  </PharagraphWrapper>
                  <StyledTextFieldForm
                    width={"100%"}
                    label="Customer Enterprise ID"
                    customerenterpriseidcy="customerEnterpriseID"
                    defaultValue={modalData.customerEnterpriseID}
                    margin="dense"
                    variant="outlined"
                    onChange={(e) =>
                      handleUpdates(e.target.value, "customereid")
                    }
                    helperText={
                      errorCustomerEID && "Please enter a primary poc"
                    }
                    error={errorCustomerEID}
                  />
                </Flex>
                <Flex>
                  <PharagraphWrapper>
                    <Pharagraph>Secondary POC:</Pharagraph>
                  </PharagraphWrapper>
                  <StyledTextFieldForm
                    width={"100%"}
                    label="Secondary POC"
                    customerenterpriseidcy="secondaryPOC"
                    defaultValue={modalData.secondaryPOC}
                    margin="dense"
                    variant="outlined"
                    onChange={(e) => handleUpdates(e.target.value, "secpoc")}
                    helperText={
                      errorSecPoc && "Please enter a secondary poc"
                    }
                    error={errorSecPoc}
                  />
                </Flex>
                <Flex>
                  <PharagraphWrapper>
                    <Pharagraph>Action:</Pharagraph>
                  </PharagraphWrapper>
                  <StyledFormControl
                    variant="outlined"
                    margin="dense"
                    style={{ marginLeft: "25%" }}
                    error={errorUserAction}
                  >
                    <InputLabel htmlFor="outlined-service-group">
                      Action
                    </InputLabel>
                    <StyledSelect
                      defaultValue="undefined"
                      value={useraction}
                      onChange={(e) => handleUpdates(e.target.value, "useraction")}
                      label="Action"
                      margin="dense"
                      variant="outlined"
                    >
                      <MenuItem value="undefined">
                        <em>--Select an action--</em>
                      </MenuItem>
                      <MenuItem value="Continue">Continue to use</MenuItem>
                      <MenuItem value="Terminate">Terminate</MenuItem>
                    </StyledSelect>
                    {/* {`errorUserAction: ${errorUserAction}`} */}

                    {errorUserAction ? <FormHelperText>Please select an action</FormHelperText> : ""}
                  </StyledFormControl>

                </Flex>
                <div>
                  <Subtext style={{ textAlign: "initial", color: "red", opacity: "100%", paddingLeft: "4%", paddingTop: "3%", wrap: "True" }}>
                    Note: <br></br>
                    You can update the server details once. For any further changes, simply reach out to our support team via a {" "}
                    <a href={supportLink} target="_blank" rel="noopener noreferrer">
                      support ticket
                    </a>
                    {" "} , and we’ll be happy to assist you.<br></br>
                  </Subtext>
                </div>
              </div>
            )
          }
          dialogActions={
            <DialogActions style={{ marginTop: "10%", marginBottom: "5%" }}>
              <ButtonWrapper>
                <DeclineButton
                  onClick={() => handleClose()}
                  canceledittagsbuttoncy="cancelEditTagsButtonCy"
                  label={"Cancel"}
                />&nbsp;&nbsp;
                <AcceptButton
                  saveedittagsbuttoncy="saveEditTagsButtonCy"
                  label={"Save"}
                  onClick={() => handleValidation()}
                >
                  {loader ? (
                    <CircularProgress
                      size={28}
                    // style={{ color: `${theme.palette.ssh.main}` }}
                    />
                  ) : "Save"}
                </AcceptButton>
              </ButtonWrapper>
            </DialogActions>
          }
        ></CommonDialog>
      </div>

      {/* View Details Modal */}

      <div>
        <CommonDialog
          open={openViewDetailsModal}
          onClose={() => handleCloseViewDetails()}
          title={"VIEW DETAILS - " + modalData.resource_id}
          dialogType={"servers"}
          dialogContent={
            modalData && (
              <>
                <div>
                  <Flex>
                    <PharagraphWrapper>
                      <Pharagraph>Client Instance:</Pharagraph>
                    </PharagraphWrapper>
                    <Pharagraph style={{ fontWeight: "400" }}>{modalData.clientInstance}</Pharagraph>
                  </Flex>
                  {modalData.sap_contract_id === "nan" ?
                    <Flex>
                      <PharagraphWrapper>
                        <Pharagraph>SAP Contract ID:</Pharagraph>
                      </PharagraphWrapper>
                      <Pharagraph style={{ fontWeight: "400" }}>-</Pharagraph>
                    </Flex>
                    :
                    <Flex>
                      <PharagraphWrapper>
                        <Pharagraph>SAP Contract ID:</Pharagraph>
                      </PharagraphWrapper>
                      <Pharagraph style={{ fontWeight: "400" }}>{modalData.sap_contract_id}</Pharagraph>
                    </Flex>
                  }
                  {modalData.external_client_name !== "-" || modalData.external_client_name !== "undefined" ?
                    <Flex>
                      <PharagraphWrapper>
                        <Pharagraph>Client Name:</Pharagraph>
                      </PharagraphWrapper>
                      <Pharagraph style={{ fontWeight: "400" }}>{modalData.external_client_name}</Pharagraph>
                    </Flex>
                    :
                    <Flex>
                      <PharagraphWrapper>
                        <Pharagraph>Client Name:</Pharagraph>
                      </PharagraphWrapper>
                      <Pharagraph style={{ fontWeight: "400" }}>{modalData.client_name}</Pharagraph>
                    </Flex>
                  }
                  <Flex>
                    <PharagraphWrapper>
                      <Pharagraph>Team Name:</Pharagraph>
                    </PharagraphWrapper>
                    <Pharagraph style={{ fontWeight: "400" }}>{modalData.teamName}</Pharagraph>
                  </Flex>
                  <Flex>
                    <PharagraphWrapper>
                      <Pharagraph>Data Classification:</Pharagraph>
                    </PharagraphWrapper>
                    <Pharagraph style={{ fontWeight: "400" }}>{modalData.dataClassification}</Pharagraph>
                  </Flex>
                  <Flex>
                    <PharagraphWrapper>
                      <Pharagraph>Environment:</Pharagraph>
                    </PharagraphWrapper>
                    <Pharagraph style={{ fontWeight: "400" }}>{modalData.environment}</Pharagraph>
                  </Flex>
                  <Flex>
                    <PharagraphWrapper>
                      <Pharagraph>WBSE Code:</Pharagraph>
                    </PharagraphWrapper>
                    <Pharagraph style={{ fontWeight: "400" }}>
                      {modalData.wbsecode}
                    </Pharagraph>
                  </Flex>
                  <Flex>
                    <PharagraphWrapper>
                      <Pharagraph>Service End Date:</Pharagraph>
                    </PharagraphWrapper>
                    <Pharagraph style={{ fontWeight: "400" }}>{modalData.serviceEndDate}</Pharagraph>
                  </Flex>
                  <Flex>
                    <PharagraphWrapper>
                      <Pharagraph>Notes:</Pharagraph>
                    </PharagraphWrapper>
                    <Pharagraph style={{ fontWeight: "400" }}>{modalData.notes}</Pharagraph>
                  </Flex>
                  <Flex>
                    <PharagraphWrapper>
                      <Pharagraph>Customer Enterprise ID:</Pharagraph>
                    </PharagraphWrapper>
                    <Pharagraph style={{ fontWeight: "400" }}>{modalData.customerEnterpriseID}</Pharagraph>
                  </Flex>
                  <Flex>
                    <PharagraphWrapper>
                      <Pharagraph>Secondary POC:</Pharagraph>
                    </PharagraphWrapper>
                    <Pharagraph style={{ fontWeight: "400" }}>{modalData.secondaryPOC}</Pharagraph>
                  </Flex>
                  <Flex>
                    <PharagraphWrapper>
                      <Pharagraph>Action Selected:</Pharagraph>
                    </PharagraphWrapper>
                    <Pharagraph style={{ fontWeight: "400" }}>{modalData.userAction == "Continue" ? "Continue to Use" : modalData.userAction == "Terminate" ? "Terminate Instance" : ""}</Pharagraph>
                  </Flex>
                </div>
                <div>
                  <Subtext style={{ textAlign: "initial", color: "red", opacity: "100%", paddingTop: "5%", paddingLeft: "5%" }}>
                    Note: For any changes, simply reach out to our support team via a {" "}
                    <a href={supportLink} target="_blank" rel="noopener noreferrer">
                      support ticket
                    </a>
                    {" "} , and we’ll be happy to assist you.<br></br>
                  </Subtext>
                </div>
              </>
            )
          }
          dialogActions={
            <DialogActions style={{ marginTop: "10%", marginBottom: "5%" }}>
              <ButtonWrapper>
                <DeclineButton
                  onClick={() => handleCloseViewDetails()}
                  canceledittagsbuttoncy="cancelEditTagsButtonCy"
                  label={"Close"}
                />&nbsp;&nbsp;
              </ButtonWrapper>
            </DialogActions>
          }
        ></CommonDialog>
      </div>

      <div>
        <CommonDialog
          open={openTerminationConfirmation}
          onClose={() => setOpenTerminationConfirm(false)}
          title={"Terminate Instance"}
          dialogType={"servers"}
          dialogContent={
            <div>
              <p style={{ padding: "20px 30px 0px 30px" }}>Are you sure you want to terminate this instance?</p>
            </div>
          }
          dialogActions={
            <DialogActions style={{ marginTop: "10%", marginBottom: "5%" }}>
              <ButtonWrapper>
                <DeclineButton
                  onClick={() => { setOpenTerminationConfirm(false); handleUpdates("undefined", "useraction"); }}
                  canceledittagsbuttoncy="cancelEditTagsButtonCy"
                  label={"No"}
                />&nbsp;&nbsp;
                <AcceptButton
                  saveedittagsbuttoncy="saveEditTagsButtonCy"
                  label={"Yes"}
                  onClick={() => { handleUpdates("Terminate", "useraction"); handleValidation(); setOpenTerminationConfirm(false); }}
                />
              </ButtonWrapper>
            </DialogActions>
          }
        ></CommonDialog>
      </div>
    </div>
  );
};

const MSTP = (state) => ({
  email: state.auth.userDetails.email,
  idToken: state.auth.idToken,
});

export default connect(MSTP)(withRouter(ServerReview));