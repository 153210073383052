import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    fetchExternalClientDetails,
} from "../../../redux/fui/index";
import { checkRootExisted } from "../../../redux/fui";
import { StyledTooltip } from "../FuiStyles";
import { longTextEndDate, rootExistedHint, rootWrongFormatHint } from "../Constants/LongText";
import { useDebounce } from "../../../helper/customizedHooks";
import { DateTextFieldCommonComponent } from "../DateTextFieldCommonComponent/DateTextFieldCommonComponent";
import * as FormField from "./IndividualFormFields";
import * as PlatformFormField from "./IndividualFormFields";
import { checkServiceNowTicket, checkWBSECode } from "./CommonFunctions";
import GeneralForm from "./GeneralForm";
import * as fuiRedux from "../../../redux/fui/index";
import { Grid } from "@material-ui/core";
// import { appNameProjectId } from "../Constants/ProductIds";

/**
 * EventOps
 * @author Poornna Aiswarya Ganeshan
 * @version 2.0
 * @see Used in component: PlatformLicenseStepper
 */

const EventOps = ({
    values, // the value would be changed by user's input
    updateEOForm, //the function to update DPForm values which is from redux
    createdBy, // user's EID
    checkDomainAvaliable, // the function that check whether DomainContextRoot exist or not from redux
    isRootExist, // the value that show whether DomainContextRoot exist or not from redux
    setIsAllValid, // the function to check whether all validations are satisfieds
    selectedEnvironment,
    minDate,
    maxDate,
    isServiceEndDateInvalid,
    getSelectedEnvironment,
    getServiceEndDate,
    getNotesValue,
    notesValidations,
    configurationType,
    classes,
    history,
    email,
    idToken,
    isExternalClientLoading,
    externalClientData,
    externalClientError,
    fetchExternalClientDetails,
    isLoading
}) => {

    // open the dialog when user choose Yes in GDPR
    const config = configurationType === "quick";

    //check whether ServiceNowTicket is valid
    const [isServiceNowTicketValid, setIsServiceNowTicketValid] = useState(true);

    // check whether WBSECode and MonthlyTicketVolume are valid
    const [isWBSECodeValid, setIsWBSECodeValid] = useState(true);
    // check whether WBSECode is equal to MD & I WBSE
    const [isWBSECodeMDIValid, setIsWBSECodeMDIValid] = useState(true);
    const [isProjectNameValid, setIsProjectNameValid] = useState(true);
    const [isDialogFlowDevToken, setIsDialogFlowDevToken] = useState(false);
    const [isDialogFlowClientToken, setIsDialogFlowClientToken] = useState(false);
    //   const [isUsersFieldValid, setUsersField] = useState(false);
    const isGDPR = values.GDPR === "yes";
    const isOpenAI = values.OpenAI === "True";
    const isDedicated = values.InstanceType === "Dedicated"
    const isAAAMTermsConditions = values.AAAMTermsConditions === "yes";
    const isDialogFlow = values.DialogFlow === "enabled";
    const isDeployServiceNowID = values.DeployServiceNowID === "True"
    const isJiraIntegration = values.DeployJiraID === "True"
    const isEmailIntegration = values.DeployEmailID === "True"
    const isATRIntegration = values.DeployATRID === "True"
    //   const isPaymentRate = values.PaymentRate === "payasyougo";

    const [sapContractCodeLength, setSapContractCodeLength] = useState("0");
    const [domainContextRoot, setDomainContextRoot] = useState("[__]");
    const [regionCode, setRegionCode] = useState("[__]");
    const [isTeamNameSelected, setIsTeamNameSelected] = useState(true);
    const [customURL, setCustomURL] = useState("https://[__].mywizard-aiops.accenture.com/[__]");

    useEffect(() => {
        if (config) {
            setDomainContextRoot("[__]");
            setRegionCode("[__]");
        }
    }, [config]);

    if (config) {
        updateEOForm("ClientName", "")
        updateEOForm("EndToEndUId", "")
        updateEOForm("Company", "")
        updateEOForm("AvanadeCountry", "")
        updateEOForm("AccentureCountry", "")
        updateEOForm("ChatGPT", "gpt-4")
    }

    if (!config) {
        if (externalClientData) {
            updateEOForm("ClientName", externalClientData[0])
            updateEOForm("EndToEndUId", externalClientData[1])
            updateEOForm("Company", externalClientData[2])
            updateEOForm("AvanadeCountry", externalClientData[3])
            updateEOForm("AccentureCountry", externalClientData[4])
            updateEOForm("ChatGPT", "gpt-4")
        }
    }

    // const sapValue = document.getElementById("SAPContractID");
    // if (sapValue) {
    //     if (sapValue.value === "") {
    //         document.getElementById("clientDetails").style.display = "none";
    //         const verifiedIcon = document.getElementById("verifiedIcon")
    //         if (verifiedIcon) {
    //             verifiedIcon.style.display = "none";
    //         }
    //     } else {
    //         document.getElementById("clientDetails").style.display = "block";
    //     }
    // }

    if (!config) {
        if (isDialogFlow) {
            (isDialogFlowClientToken ||
                isDialogFlowDevToken) &&
                !isServiceEndDateInvalid && sapContractCodeLength == 10 && !externalClientError && isWBSECodeValid && isWBSECodeMDIValid && isServiceNowTicketValid && isProjectNameValid && isTeamNameSelected
                ? setIsAllValid(true)
                : setIsAllValid(false);
        } else {
            !isServiceEndDateInvalid && sapContractCodeLength == 10 && !externalClientError && isWBSECodeValid && isWBSECodeMDIValid && isServiceNowTicketValid && isProjectNameValid && isTeamNameSelected
                ? setIsAllValid(true)
                : setIsAllValid(false);
        }
    } else {
        (isServiceNowTicketValid ||
            isDialogFlowClientToken ||
            isDialogFlowDevToken) &&
            !isServiceEndDateInvalid && isWBSECodeValid && isWBSECodeMDIValid && isProjectNameValid && isTeamNameSelected
            ? setIsAllValid(true)
            : setIsAllValid(false);
    }

    // // change validation status
    // (isServiceNowTicketValid || 
    //   isWBSECodeValid || 
    //   isDialogFlowClientToken || 
    //   isDialogFlowDevToken) &&
    // !isServiceEndDateInvalid
    //   ? setIsAllValid(true)
    //   : setIsAllValid(false);


    // user can only input lower case letter and numbers in DomainContextRoot
    const handleChangeRoot = (event, fieldName) => {
        const re = /^[a-z0-9]*$/;
        const value = event.target.value;
        re.test(value) && updateEOForm(fieldName, value);
        setDomainContextRoot(value)
    };

    const handleChangeRootSuggestions = (event, value, fieldName) => {
        const re = /^[a-z0-9]*$/;
        // const value = event.target.value;
        re.test(value) && updateEOForm(fieldName, value);
        setDomainContextRoot(value)
    };

    // get user's input
    const handleChange = (event, fieldName) => {
        if (fieldName === "TentativeServiceEndDate") {
            updateEOForm(fieldName, event);
            getServiceEndDate(event);
        } else if (fieldName === "TeamName") {
            if (event !== undefined || event !== null) {
                updateEOForm(fieldName, event);
                setIsTeamNameSelected(true);
                if (values.TeamName !== "Others") {
                    updateEOForm("TeamNameOthers", "")
                }
            } else {
                setIsTeamNameSelected(false);
            }
        }
        else {
            const { value } = event.target;
            event.preventDefault();
            updateEOForm(fieldName, value);
            if (fieldName === "WBSEcode") {
                const wbsevalue = value.toUpperCase()
                if (wbsevalue.trim() === "CBYDQ001") {
                    setIsWBSECodeMDIValid(false)
                }
                setIsWBSECodeValid(checkWBSECode(value));
            }
            if (fieldName === "ProjectName") {
                if (value.length > 32 || value.length < 3) {
                    setIsProjectNameValid(false)
                } else {
                    setIsProjectNameValid(true)
                }
            }
            if (fieldName === "RITM") {
                setIsServiceNowTicketValid(checkServiceNowTicket(value));
            }
            if (fieldName === "DialogFlowDevToken") {
                if (value.length > 0) {
                    setIsDialogFlowDevToken(true);
                } else {
                    setIsDialogFlowDevToken(false);
                }
            }
            if (fieldName === "DialogFlowClientToken") {
                if (value.length > 0) {
                    setIsDialogFlowClientToken(true);
                } else {
                    setIsDialogFlowClientToken(false);
                }
            }
            if (fieldName === "Environment") {
                getSelectedEnvironment(value);
            }
            if (fieldName === "InstanceType") {
                if (value == "Dedicated") {
                } else {
                    updateEOForm("CAMPortalEnvironment", "Production");
                }
            }
            if (fieldName === "TentativeServiceEndDate") {
                getServiceEndDate(value);
            }
            if (fieldName === "RequestNotes") {
                if (value.length > 0 && value.length < 247) {
                    getNotesValue({ emptyString: false, maxLength: false });
                } else if (value.length === 0) {
                    getNotesValue({ emptyString: true, maxLength: false });
                } else if (value.length > 246) {
                    getNotesValue({ emptyString: false, maxLength: true });
                }
            }
            if (fieldName === "SAPContractID") {
                let sapLength = value.length
                setSapContractCodeLength(sapLength)
                if (sapLength == 10) {
                    fetchExternalClientDetails(value)
                }
            }
            if (fieldName === "OpenAI") {
                if (event.target.checked === false) {
                    updateEOForm(fieldName, "False");
                } else {
                    updateEOForm(fieldName, "True");
                }
            }
            if (fieldName === "DeployServiceNowID") {
                if (event.target.checked === false) {
                    updateEOForm(fieldName, "False");
                } else {
                    updateEOForm(fieldName, "True");
                }
            }
            if (fieldName === "DeployJiraID") {
                if (event.target.checked === false) {
                    updateEOForm(fieldName, "False");
                } else {
                    updateEOForm(fieldName, "True");
                }
            }
            if (fieldName === "DeployEmailID") {
                if (event.target.checked === false) {
                    updateEOForm(fieldName, "False");
                } else {
                    updateEOForm(fieldName, "True");
                }
            }
            if (fieldName === "DeployATRID") {
                if (event.target.checked === false) {
                    updateEOForm(fieldName, "False");
                } else {
                    updateEOForm(fieldName, "True");
                }
            }
            if (fieldName === "DeploymentRegion") {
                if (value == "sydney") {
                    setRegionCode("dh")
                } else if (value == "virginia") {
                    setRegionCode("dh1")
                } else if (value == "frankfurt") {
                    setRegionCode("dh2")
                } else if (value == "singapore") {
                    setRegionCode("dh3")
                } else if (value == "tokyo") {
                    setRegionCode("dh4")
                } else if (value == "ireland") {
                    setRegionCode("dh5")
                } else if (value == "london") {
                    setRegionCode("dh6")
                } else {
                    setRegionCode("[__]")
                }
            }
        }
    };

    updateEOForm("ConfigurationType", configurationType);


    // it would check whether the root has existed from DB once user stop typing longer than 0.3s
    const debouncedValue = useDebounce(domainContextRoot, 300);
    useEffect(() => {
        debouncedValue && checkDomainAvaliable(debouncedValue);
    }, [debouncedValue]);

    return (
        <>
            <GeneralForm
                FormId={4}
                ClientContractFields={
                    <div style={{ flexGrow: 1 }}>
                        <Grid
                            container
                            spacing={2}
                        >
                            {!config &&
                                <Grid item xs={4}>
                                    {PlatformFormField.SAPContractIDPlatformField(values, handleChange, externalClientError, isExternalClientLoading, sapContractCodeLength)}
                                </Grid>}

                            {sapContractCodeLength == 10 && sapContractCodeLength != 0 && !isExternalClientLoading && !externalClientError
                                ? !config &&
                                <Grid item xs={4}> {PlatformFormField.ClientNamePlatformField(values, handleChange, externalClientData[0])}</Grid>
                                : ""
                            }


                            {sapContractCodeLength == 10 && !isExternalClientLoading && !externalClientError ?
                                !config &&
                                <Grid item xs={4}> {PlatformFormField.EndToEndUIdPlatformField(values, handleChange, externalClientData[1])}</Grid>
                                : ""
                            }

                            <Grid item xs={4}>
                                {FormField.ProjectNameField(handleChange, values, isProjectNameValid)}
                            </Grid>
                            <Grid item xs={4}>
                                {FormField.WBSECodeField(handleChange, values, isWBSECodeValid, isWBSECodeMDIValid)}
                            </Grid >
                            {
                                <Grid item xs={4}>
                                    {FormField.ServiceNowTicket(handleChange, values, isServiceNowTicketValid)}
                                </Grid>}
                            <Grid item xs={4}>
                                {FormField.SecondaryContact(handleChange, values, config)}
                            </Grid>
                            <Grid item xs={4}>
                                {FormField.TeamNamesField(handleChange, values, isTeamNameSelected)}
                            </Grid >
                            {values.TeamName === "Others" &&
                                <Grid item xs={4}>
                                    {FormField.TeamNameOthers(handleChange, values)}
                                </Grid >
                            }
                            <Grid item xs={4}>
                                {config &&
                                    FormField.ClientNamePlatformInternal(handleChange, values)}
                            </Grid >


                            <Grid item xs={12}>
                                {PlatformFormField.RequestNotesPlatformField(
                                    values,
                                    handleChange,
                                    notesValidations
                                )}
                            </Grid>
                        </Grid>
                    </div>
                }
                EnvironmentFields={
                    <div>
                        {/* {!config ? <p> Expected Custom URL: <span style={{ color: " #0041F0" }}>https://{regionCode}.mywizard-aiops.com/{domainContextRoot}</span></p> : ""} */}
                        <div style={{ flexGrow: 1 }}>
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid item xs={12}>
                                    {PlatformFormField.GDPREventOpsPlatformField(
                                        values,
                                        handleChange,
                                        isGDPR,
                                        classes,
                                        config
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    {PlatformFormField.DeploymentRegionEventOpsPlatformField(
                                        values,
                                        handleChange,
                                        isGDPR,
                                        isDedicated,
                                        config
                                    )}
                                </Grid>
                                {!config &&
                                    <Grid item xs={6}>
                                        {PlatformFormField.DomainContextRootPlatformFieldClient(
                                            values,
                                            handleChangeRootSuggestions,
                                            // handleChangeRoot,
                                            isRootExist,
                                            rootExistedHint,
                                            rootWrongFormatHint,
                                            externalClientData,
                                            domainContextRoot,
                                            isLoading
                                        )}
                                    </Grid>}
                                <Grid item xs={3}>
                                    {PlatformFormField.EnvironmentEventOpsPlatformField(values, handleChange, config)}
                                </Grid>

                            </Grid>
                        </div>
                    </div>
                }

                ApplicationFields={
                    <div style={{ flexGrow: 1 }}>
                        <Grid
                            container
                            spacing={2}
                        >
                            {/* <Grid item xs={6}>
                {PlatformFormField.InstanceTypePlatformField(
                  values,
                  handleChange,
                  isDedicated,
                  classes
                )}
              </Grid> */}

                            <Grid item xs={6}>
                                {FormField.AdminDistributionGroupField(handleChange, values)}
                            </Grid>
                            <Grid item xs={6}>
                                <StyledTooltip
                                    title={longTextEndDate}
                                    placement="left-start"
                                    noValidate
                                >
                                    <DateTextFieldCommonComponent
                                        tentativeServiceEndDate={values.TentativeServiceEndDate}
                                        selectedEnvironment={selectedEnvironment}
                                        isServiceEndDateInvalid={isServiceEndDateInvalid}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        handleChange={handleChange}
                                    />
                                </StyledTooltip>
                            </Grid>
                            <Grid item xs={6}>
                                <div style={{ paddingTop: "10px" }}>
                                    <label className="MuiFormLabel-root" >Application Integrations</label>
                                    <div style={{ display: 'flex', paddingTop: "10px" }}>
                                        {FormField.DeployServiceNowIDField(handleChange, values, isDeployServiceNowID)}

                                        {FormField.DeployJiraIDField(handleChange, values, isJiraIntegration)}

                                        {FormField.DeployEmailIDField(handleChange, values, isEmailIntegration)}

                                        {FormField.DeployATRIDField(handleChange, values, isATRIntegration)}
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                }
                LeftColumnFields={
                    <div>
                    </div>
                }
                RightColumnFields={
                    <div>
                    </div>
                }
                history={history}
            ></GeneralForm>

        </>
    );
};

const MSTP = (state, ownProps) => ({
    values: state.fui.EventOpsForm,
    isRootExist: state.fui.isRootExist,
    configurationType: state.fui.configurationType,
    email: state.auth.userDetails.email,
    idToken: state.auth.idToken,
    externalClientData: state.fui.externalClientData,
    externalClientError: state.fui.externalClientError,
    isExternalClientLoading: state.fui.isExternalClientLoading,
    isLoading: state.fui.isLoading,
});

export default connect(MSTP, {
    checkDomainAvaliable: checkRootExisted,
    updateEOForm: fuiRedux.updateEOForm,
    fetchExternalClientDetails,
})(withRouter(EventOps));
